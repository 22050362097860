import { useState } from "react"
import DialogTitle from "@mui/joy/DialogTitle"
import Modal from "@mui/joy/Modal"
import ModalClose from "@mui/joy/ModalClose"
import ModalDialog from "@mui/joy/ModalDialog"
import Stack from "@mui/joy/Stack"
import "react-phone-number-input/style.css"
import StudyOrderForm from "./StudyOrderForm"
import { GetAllStudyOrdersRequest } from "../../../generated/fetchclient"

export const emptySelectedPatient = {
  uuid: "",
  name: "",
}

interface NewStudyModalProps {
  newStudyModalOpenStatus: boolean
  setNewStudyModalOpenStatus: React.Dispatch<React.SetStateAction<boolean>>
  studyQuery: GetAllStudyOrdersRequest
}

function NewStudyModal({
  newStudyModalOpenStatus,
  setNewStudyModalOpenStatus,
  studyQuery,
}: NewStudyModalProps) {
  const [page, setPage] = useState(1)
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={newStudyModalOpenStatus}
      onClose={() => {
        setPage(1)
        setNewStudyModalOpenStatus(false)
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog sx={{ maxWidth: 880, overflowY: "auto" }}>
        <ModalClose />
        <Stack spacing={2}>
          <DialogTitle>
            Create New Study:
            {page === 1 ? " Select Patient" : " Study Details"}
          </DialogTitle>
          <StudyOrderForm
            setOpen={setNewStudyModalOpenStatus}
            page={page}
            setPage={setPage}
            studyQuery={studyQuery}
          />
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

export default NewStudyModal
