import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import { GetAllUsersRequest, UserIn } from "../generated/fetchclient"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { userCount, users } from "../state/users.state"
import { handleApiError } from "../utils/apiUtils"
import { currentUser } from "../state/auth.state"

const useUsers = () => {
  const api = usePortalApi()
  const setCurrentUser = useSetRecoilState(currentUser)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setUsers = useSetRecoilState(users)
  const setUserCount = useSetRecoilState(userCount)
  const resetUsers = useResetRecoilState(users)
  const resetUsersCount = useResetRecoilState(userCount)

  const getUser = async (userId: string) => {
    if (api === undefined) return

    try {
      const user = await api.getUser({ userId })
      return user
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const updateCurrentUser = async () => {
    if (api === undefined) return
    try {
      const userData = await api.currentUser()
      setCurrentUser(userData)
      return true
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Creates a new user.
   *
   * @param user The details of the user to create.
   */
  const createUser = async (user: UserIn) => {
    if (api === undefined) return

    try {
      await api.createUser({ userIn: user })
      setSnackAlertMsg({
        open: true,
        message: "User Created",
        severity: "success",
        autoHideDuration: 5000,
      })
      await listUsers()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes a user by its ID.
   *
   * @param userId The ID of the user to delete.
   */
  const deleteUser = async (userId: string) => {
    if (api === undefined) return

    try {
      await api.deleteUser({ userId })
      setSnackAlertMsg({
        open: true,
        message: "User Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      listUsers()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists users.
   *
   * @param query The query to filter the list of users.
   */
  const listUsers = async (query: GetAllUsersRequest = {}) => {
    if (api === undefined) return

    try {
      const users = await api.getAllUsers(query)
      setUsers(users.items)
      setUserCount(users.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Sets the active status of a user.
   *
   * @param userId The ID of the user to update.
   * @param isActive The active status to set.
   */
  const setUserActive = async (userId: string, isActive: boolean) => {
    if (api === undefined) return

    try {
      await api.updateUser({
        userId,
        userUpdate: {
          isActive,
        },
      })
      listUsers()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const resetUserData = () => {
    resetUsers()
    resetUsersCount()
  }

  return {
    users: useRecoilValue(users),
    userCount: useRecoilValue(userCount),
    createUser,
    getUser,
    deleteUser,
    listUsers,
    setUserActive,
    resetUserData,
    updateCurrentUser,
  }
}

export default useUsers
