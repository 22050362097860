import { Bar } from "@visx/shape"
import { scaleLinear } from "@visx/scale"
import { Axis, Orientation, TickFormatter } from "@visx/axis"
import { Group } from "@visx/group"
import { useMemo, useRef } from "react"
import { useRecoilValue } from "recoil"
import {
  allPlotTimeDomain,
  sleepPlotSignalData,
} from "../../../state/signals.state"
import { eventIDs } from "../../../state/event.state"
import { StyledSvg } from "@huxley-medical/react-components/components"
import {
  getAlignedTicksInterval,
  padDomain,
} from "@huxley-medical/react-components/utils"
import { Clear } from "@huxley-medical/react-components/elements"
import { hourMs } from "../../../constants"
import EpochScrollWindow from "./EpochScrollWindow"
import AnnotatedEvent from "./AnnotatedEvent"
import { StyledStepLinePlot, StyledTimestamp } from "../../../styled/Div"
import { NumberValue } from "d3-scale"
import GraphStepLinePlot from "../Graph/GraphStepLinePlot"
import GridGraphColumns from "../Graph/GridGraphColumns"

/**
 * HyponogramScroller is used to render the hypnogram above the stacked line plots.
 * Renders the epoch window slider that controls the visible epochs in stacked
 * line plots below.
 *
 * @param {HyponogramScrollerParams} HyponogramScrollerParams
 * @returns {JSX.Element} JSX.Element
 */

type HyponogramScrollerParams = {
  tickFormat?: TickFormatter<NumberValue>
  yDomainPaddingPercentage?: number
  width: number
  height: number
}

const HyponogramScroller = ({
  tickFormat,
  yDomainPaddingPercentage = 15,
  width,
  height,
}: HyponogramScrollerParams): JSX.Element => {
  const sleepPlot = useRecoilValue(sleepPlotSignalData("SANSASleepStage"))

  // Ensure that this plot contains the range for everything
  // not sure why the data doesn't line up anyway
  const [allXMin, allXMax] = useRecoilValue(allPlotTimeDomain)

  // For coloring events
  const events = useRecoilValue(eventIDs)

  // Determine time scale domain
  const xMin = allXMin
  const xMax = allXMax

  // Determine y-scale domain
  const yMin = sleepPlot?.signalMinValue ?? 0
  const yMax = sleepPlot?.signalMaxValue ?? 1

  // Used for rel mouse coord calulations
  const parentRef = useRef<SVGSVGElement>(null)

  const marginWidth = (width === 0 ? 40 : width) - 40

  // Setup scales based on window size
  const timeScale = useMemo(() => {
    return scaleLinear<number>({
      domain: [xMin, xMax],
      range: [0, marginWidth],
    })
  }, [xMin, xMax, marginWidth])

  const yScale = useMemo(() => {
    return scaleLinear<number>({
      domain: padDomain([yMin, yMax], yDomainPaddingPercentage),
      range: [0, height],
    })
  }, [yMin, yMax, yDomainPaddingPercentage, height])

  return (
    <>
      <StyledStepLinePlot>
        <StyledSvg width={width} height={height}>
          <Group left={40}>
            <StyledSvg ref={parentRef} width={marginWidth} height={height}>
              <Bar width={marginWidth} height={height} fill="#F5F9FB" />
              <GridGraphColumns
                scale={timeScale}
                width={marginWidth}
                height={height}
                tickIntervals={hourMs}
                stroke="#C9C9C9"
              />
              {sleepPlot && sleepPlot?.data?.values?.length > 0 && (
                <GraphStepLinePlot
                  data={sleepPlot.data}
                  plotWidth={marginWidth}
                  lineColor="#000"
                  lineHeight={height}
                  timeScale={timeScale}
                  yScale={yScale}
                />
              )}
              {events.map((evt) => (
                <AnnotatedEvent
                  key={evt}
                  height={height}
                  eventID={evt}
                  timeScale={timeScale}
                />
              ))}

              <EpochScrollWindow
                width={marginWidth}
                height={height}
                timeScale={timeScale}
                parentRef={parentRef}
              />
            </StyledSvg>
          </Group>

          <Axis
            top={2}
            left={22}
            hideTicks
            hideAxisLine
            tickFormat={tickFormat}
            tickValues={getAlignedTicksInterval({
              minTick: yMin,
              maxTick: yMax,
              tickInterval: 1,
            })}
            orientation={Orientation.left}
            scale={yScale}
          />
        </StyledSvg>
      </StyledStepLinePlot>
      <StyledTimestamp sx={{ float: "left" }}>
        {new Date(xMin * 1000).toLocaleTimeString()}
      </StyledTimestamp>
      <StyledTimestamp sx={{ float: "right" }}>
        {new Date(xMax * 1000).toLocaleTimeString()}
      </StyledTimestamp>
      <Clear />
    </>
  )
}

export default HyponogramScroller
