import { Dispatch, ReactNode, SetStateAction } from "react"
import Modal from "@mui/joy/Modal"
import ModalDialog, {
  ModalDialogPropsSizeOverrides,
} from "@mui/joy/ModalDialog"
import Stack from "@mui/joy/Stack"
import ModalClose from "@mui/joy/ModalClose"
import DialogTitle from "@mui/joy/DialogTitle"
import { OverridableStringUnion } from "@mui/types"
import Divider from "@mui/material/Divider"
import { SxProps } from "@mui/joy/styles/types"

interface ModalBoxProps {
  open: boolean
  title?: string
  setOpen: Dispatch<SetStateAction<boolean>>
  children: ReactNode
  width?: number | string
  size?:
    | OverridableStringUnion<"sm" | "md" | "lg", ModalDialogPropsSizeOverrides>
    | undefined
  dialogSx?: SxProps
}

const ModalBox = ({
  open,
  title,
  setOpen,
  children,
  width = 880,
  size,
  dialogSx,
}: ModalBoxProps) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <ModalDialog
        sx={{
          width: width + "px",
          transition: "width 0.3s ease-in-out",
          ...(dialogSx || {}),
        }}
      >
        <ModalClose />
        {title && (
          <Stack spacing={1}>
            <DialogTitle>{title}</DialogTitle>
            <Divider />
          </Stack>
        )}
        {children}
      </ModalDialog>
    </Modal>
  )
}

export default ModalBox
