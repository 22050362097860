import Button from "@mui/joy/Button"
import Divider from "@mui/joy/Divider"
import Grid from "@mui/joy/Grid"
import React, { useState } from "react"
import { Value } from "react-phone-number-input/input"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import { PatientIn } from "../../../../src/generated/fetchclient"
import { isHuxleyUser } from "../../../state/auth.state"
import useOrganizations from "../../../hooks/useOrganizations"
import usePatients from "../../../hooks/usePatients"
import { EditModalPatientData } from "../../../types/event.type"
import PatientFormFields from "../PatientFormFields"
import { validatePatientForm } from "../../../utils/validateUtils"

type NewPatientFormParams = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  patientData?: EditModalPatientData
}

const emptyPatientInput = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  dob: new Date(),
  mrn: "",
  race: "",
  sex: "",
  organizationId: "",
}

const emptyInputErrors = {
  firstName: "",
  lastName: "",
  email: "",
  primaryPhone: "",
  referringPhysicianPhone: "",
}

function NewPatientForm({ setOpen, patientData }: NewPatientFormParams) {
  const { organizations } = useOrganizations()
  const isAdmin = useRecoilValue(isHuxleyUser)
  const api = usePortalApi()
  const patientsApi = usePatients()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [patientInput, setPatientInput] = useState<PatientIn>(
    patientData || emptyPatientInput
  )
  const [inputErrors, setInputErrors] = useState(emptyInputErrors)

  const handlePropChange = (
    property: keyof PatientIn,
    value: Date | string | Value
  ) => {
    setPatientInput({
      ...patientInput,
      [property]: value,
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (!validatePatientForm(patientInput, setInputErrors)) return

    patientData
      ? await patientsApi.updatePatient(patientData.uuid ?? "", patientInput)
      : await patientsApi.createPatient(patientInput)

    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <PatientFormFields
          patientInput={patientInput}
          inputErrors={inputErrors}
          handlePropChange={handlePropChange}
          showOrganizationField={isAdmin}
          organizations={organizations}
        />
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <Button type="submit">
            {patientData ? "Edit Patient" : "Create Patient"}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewPatientForm
