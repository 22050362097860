import React, { Suspense, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Div } from "@huxley-medical/react-components/elements"
import { ParentSize } from "@visx/responsive"
import Box from "@mui/material/Box"
import Stack from "@mui/joy/Stack"
import { JSONContent } from "@tiptap/core"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  StyledDatasetHeader,
  StyledStudyDatasetContent,
} from "../../styled/Div"
import { uploadedSignatureUrl } from "../../state/signature.state"
import {
  currentStudy,
  editEventExclusionStatus,
  isLoadingStudy,
} from "../../state/study.state"
import { isTemplateLoading } from "../../state/interpretationtemplate.state"
import { chartWidth, disableGraph, mainHeight } from "../../state/graph.state"
import { currentUser } from "../../state/auth.state"
import { isSignatureLoading } from "../../state/signature.state"
import useInterpretationTemplate from "../../hooks/useInterpretationTemplate"
import { setChartStatus, statusStrToEnum } from "../../utils/studyUtils"
import { handleApiError } from "../../utils/apiUtils"
import { initialEditorData } from "../../utils/tipTapUtils"
import useLoadStudy from "../../hooks/useLoadStudy"
import useInterpretStudy from "../../hooks/useInterpretStudy"
import useSignature from "../../hooks/useSignature"
import StudyScoringHeader from "../../views/scoring/StudyScoringHeader"
import IntervalFilter from "../../components/scoring/intervals/IntervalFilter"
import StackedPlots from "../../components/scoring/StackedPlots"
import HyponogramScroller from "../../components/scoring/hypnogram/HypnogramScroller"
import ScoringHeader from "../../components/scoring/scoringheader/ScoringHeader"
import { snackAlert } from "../../components/SnackAlerts"
import InterpretationDrawer from "./interpretationdrawer/InterpretationDrawer"
import { usePortalApi } from "../../connections"
import { getBase64FromUrl } from "../../utils/imageUtils"
import usePrintPdf from "../../hooks/usePrintPdf"
import { pdfUrl } from "../../state/pdf.state"
import useResetRecoilStateData from "../../hooks/useResetRecoilStateData"
import useStudies from "../../hooks/useStudies"
import Loaders from "./loaders/Loaders"
import useUsers from "../../hooks/useUsers"
import { EventPlot } from "../../types/event.type"
import { isInvalidNumber } from "../../utils/utils"
import { isGeneratingPDFReport } from "../../state/pdfReport.state"
import { SleepStageMap } from "../../const/signals.const"

const drawerWidth = 350
const SignatureModal = React.lazy(
  () => import("./signaturemodal/SignatureModal")
)
const InterpretationTemplateModal = React.lazy(
  () => import("./interpretationtemplatemodal/InterpretationTemplateModal")
)
const PreviewPdfModal = React.lazy(
  () => import("./previewmodal/PreviewPdfModal")
)
const PlotOrderModal = React.lazy(
  () => import("../../components/scoring/plotOrderModal/plotOrderModal")
)
const YScaleModal = React.lazy(
  () => import("../../components/scoring/yScaleModal/YScaleModal")
)

const StudyScoringContent = () => {
  const chartRef = useRef<HTMLDivElement>(null)
  const headerHeight = useRef<HTMLDivElement>(null)
  const scoringHypnogramHeight = useRef<HTMLDivElement>(null)
  const signatureRef = useRef<HTMLCanvasElement | null>(null)
  const [openInterpretationDrawerStatus, setOpenInterpretationDrawerStatus] =
    useState<boolean>(false)
  const [interpretationDrawerWidth, setInterpretationDrawerWidth] =
    useState(drawerWidth)
  const [interpretationEditorContentData, setInterpretationEditorContentData] =
    useState<JSONContent>({ ...initialEditorData })
  const [
    initialInterpretationEditorContentData,
    setInitialInterpretationEditorContentData,
  ] = useState<JSONContent>({ ...initialEditorData })
  const [
    interpretationTemplateModalStatus,
    setInterpretationTemplateModalStatus,
  ] = useState<boolean>(false)
  const [signatureModalStatus, setSignatureModalStatus] =
    useState<boolean>(false)
  const [previewPdfModalStatus, setPreviewPdfModalStatus] =
    useState<boolean>(false)
  const [loadingPreviewModal, setPreviewLoadingModal] = useState<boolean>(false)
  const [studyInterpreting, setStudyInterpreting] = useState<boolean>(false)
  const [signatureRadioValue, setSignatureRadioValue] = useState("upload")
  const [droppedFile, setDroppedFile] = useState<File | null>(null)
  const [editSignature, setEditSignature] = useState<boolean>(false)
  const [openPlotOrderModal, setOpenPlotOrderModal] = useState(false)
  const [autoScaling, setAutoScaling] = useState(false)
  const [scaleModalOpen, setScaleModalOpen] = useState({
    open: false,
    plotType: "",
  })
  const [yScales, setYScales] = useState({
    Position: { minY: 0, maxY: 5 },
    SANSASleepStage: { minY: 1, maxY: 0 },
    SpO2: { minY: 60, maxY: 100 },
    HR: { minY: NaN, maxY: NaN },
    Actigraphy: { minY: NaN, maxY: NaN },
    Chest: { minY: NaN, maxY: NaN },
    Resp: { minY: NaN, maxY: NaN },
    Snoring: { minY: NaN, maxY: NaN },
    ECG: { minY: NaN, maxY: NaN },
  })
  const [newYScales, setNewYScales] = useState({ minY: NaN, maxY: NaN })
  const { studyID } = useParams()
  const api = usePortalApi()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const studies = useStudies()
  const printSleepPdf = usePrintPdf(chartRef)
  const interpretationTemplateApi = useInterpretationTemplate()
  const { setPlotData } = useLoadStudy()
  const { updateCurrentUser } = useUsers()
  const interpretStudyApi = useInterpretStudy()
  const signatureApi = useSignature()
  const user = useRecoilValue(currentUser)
  const pdfUrlLink = useRecoilValue(pdfUrl)
  const chartWidthTotal = useRecoilValue(chartWidth)
  const mainHeightTotal = useRecoilValue(mainHeight)
  const isGeneratingPDF = useRecoilValue(isGeneratingPDFReport)
  const isLoading = useRecoilValue(isLoadingStudy)
  const editEventExclusionChangeStatus = useRecoilValue(
    editEventExclusionStatus
  )
  const study = useRecoilValue(currentStudy)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setIsSignatureLoadingStatus = useSetRecoilState(isSignatureLoading)
  const setDisableGraph = useSetRecoilState(disableGraph)
  const [isInterpretationTemplateLoading, setIsInterpretationTemplateLoading] =
    useRecoilState(isTemplateLoading)
  const [signatureUrl, setSignatureUrl] = useRecoilState(uploadedSignatureUrl)
  const { resetGraphData } = useResetRecoilStateData()
  const handleOpenPlotOrderModal = () => {
    setOpenPlotOrderModal(true)
  }
  const openInterpretationTemplateModal = async () => {
    setIsInterpretationTemplateLoading(true)
    await interpretationTemplateApi.listInterpretationTemplates({})
    setInterpretationTemplateModalStatus(true)
    setIsInterpretationTemplateLoading(false)
  }
  const onInterpretationEditorContentChange = (contentData: JSONContent) => {
    setIsInterpretationTemplateLoading(true)
    setInterpretationEditorContentData(contentData)
    setIsInterpretationTemplateLoading(false)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignatureRadioValue(event.target.value)
  }
  const openInterpretationDrawer = () => {
    setOpenInterpretationDrawerStatus(true)
  }

  const interpretCurrentStudy = async () => {
    try {
      setStudyInterpreting(true)
      await studies.createSleepStudyReportUrl({ studyId: studyID as string })
      await interpretStudyApi.interpretStudy({
        interpretationEditorContentData,
      })
      setInterpretationEditorContentData({ ...initialEditorData })
      setOpenInterpretationDrawerStatus(false)
      await studies.fetchStudy(studyID as string)
      const link = document.createElement("a")
      link.href = pdfUrlLink
      link.download = studyID as string
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
      setStudyInterpreting(false)
      setPreviewPdfModalStatus(false)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const uploadSignature = async (droppedFile: File) => {
    if (droppedFile === null || user === undefined || user.uuid === undefined)
      return

    try {
      const uploadingSignature = await signatureApi.uploadSignatureImage(
        user?.uuid,
        droppedFile
      )
      if (uploadingSignature) {
        return true
      } else {
        return false
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const saveSignature = async () => {
    if (user === undefined || user.uuid === undefined) return

    setIsSignatureLoadingStatus(true)
    if (signatureUrl !== undefined && !editSignature) {
      setSignatureModalStatus(false)
      setIsSignatureLoadingStatus(false)
      setPreviewLoadingModal(true)
      await printSleepPdf(interpretationEditorContentData, false)
      await setPreviewPdfModalStatus(true)
      setPreviewLoadingModal(false)
      return
    }

    if (signatureRadioValue === "upload") {
      if (droppedFile === null) {
        setSnackAlertMsg({
          open: true,
          message: "Please select a file",
          severity: "error",
          autoHideDuration: 5000,
        })
        return
      }
      if (await uploadSignature(droppedFile)) {
        await updateCurrentUser()
        setSignatureModalStatus(false)
        setIsSignatureLoadingStatus(false)
        setPreviewLoadingModal(true)
        setSnackAlertMsg({
          open: true,
          message: "Signature uploaded",
          severity: "success",
          autoHideDuration: 5000,
        })
        await printSleepPdf(interpretationEditorContentData, false)
        setPreviewPdfModalStatus(true)
        setPreviewLoadingModal(false)
      } else {
        setIsSignatureLoadingStatus(false)
      }
    } else if (signatureRadioValue === "signature") {
      const canvas = signatureRef.current
      if (canvas !== null) {
        canvas.toBlob(async (blob) => {
          if (blob !== null) {
            const convertedFile = new File([blob], "signature.png", {
              type: "image/png",
            })
            if (await uploadSignature(convertedFile)) {
              await updateCurrentUser()
              setSignatureModalStatus(false)
              setIsSignatureLoadingStatus(false)
              setPreviewLoadingModal(true)
              setSnackAlertMsg({
                open: true,
                message: "Signature uploaded",
                severity: "success",
                autoHideDuration: 5000,
              })
              await printSleepPdf(interpretationEditorContentData, false)
              setPreviewPdfModalStatus(true)
              setPreviewLoadingModal(false)
            } else {
              setIsSignatureLoadingStatus(false)
            }
          }
        }, "image/png")
      }
    }
  }

  const openSignatureModal = async () => {
    setIsSignatureLoadingStatus(true)
    if (user !== undefined && user.uuid !== undefined && user?.hasSignature) {
      const url = await signatureApi.getSignatureUrl({
        physicianId: user.uuid,
      })
      const signatureImage: string = await getBase64FromUrl(url as string)
      await setSignatureUrl(signatureImage)
    }
    setSignatureModalStatus(true)
    setIsSignatureLoadingStatus(false)
  }

  const handleOpenScaleModal = (plotType: string) => {
    // Position and sleep plots cannot be rescaled
    if (plotType === "Position" || plotType === "SANSASleepStage") {
      return
    }
    // Set autoscaling checkbox if currently autoscaling plot
    if (
      Number.isNaN(yScales[plotType as EventPlot].minY) &&
      Number.isNaN(yScales[plotType as EventPlot].maxY)
    ) {
      setAutoScaling(true)
    }
    // Set current scale values to modal
    setNewYScales({
      minY: yScales[plotType as EventPlot].minY,
      maxY: yScales[plotType as EventPlot].maxY,
    })
    setScaleModalOpen({ open: true, plotType })
  }

  const handleCloseScaleModal = () => {
    setNewYScales({ minY: NaN, maxY: NaN })
    setScaleModalOpen({ open: false, plotType: "" })
  }

  const handleSaveScaleModal = () => {
    if (autoScaling) {
      setYScales({
        ...yScales,
        [scaleModalOpen.plotType]: {
          minY: NaN,
          maxY: NaN,
        },
      })
      handleCloseScaleModal()
      return
    }
    if (newYScales.minY > newYScales.maxY) {
      setSnackAlertMsg({
        open: true,
        message: "Minimum value must be less than maximum value",
        severity: "error",
      })
    } else {
      setYScales({
        ...yScales,
        [scaleModalOpen.plotType]: {
          minY: newYScales.minY,
          maxY: newYScales.maxY,
        },
      })
      handleCloseScaleModal()
    }
  }

  useEffect(() => {
    if (!api || !studyID) return

    setPlotData(studyID)
  }, [api, studyID])

  useEffect(() => {
    if (!user || !study) return

    setDisableGraph(
      setChartStatus({ user, status: statusStrToEnum[study.status] })
    )
  }, [study, user])

  useEffect(() => {
    if (isLoading) return

    const updateDimensions = () => {
      if (scoringHypnogramHeight.current) {
        if (scoringHypnogramHeight.current.offsetHeight === 0) return
        const scoringHypnogramHeightLength = scoringHypnogramHeight.current
          ? scoringHypnogramHeight.current.offsetHeight
          : 0
        const scoringHypnogramHeightComputedStyle = window.getComputedStyle(
          scoringHypnogramHeight.current
        )
        const scoringHypnogramHeightComputedStyleMarginTop = parseFloat(
          scoringHypnogramHeightComputedStyle.marginTop
        )
        const scoringHypnogramHeightComputedStyleMarginBottom = parseFloat(
          scoringHypnogramHeightComputedStyle.marginBottom
        )
        const width = isGeneratingPDF
          ? 1250
          : chartWidthTotal -
            (openInterpretationDrawerStatus ? interpretationDrawerWidth : 0)
        const height = isGeneratingPDF
          ? 800
          : mainHeightTotal -
            scoringHypnogramHeightLength -
            scoringHypnogramHeightComputedStyleMarginTop -
            scoringHypnogramHeightComputedStyleMarginBottom -
            8

        setDimensions({
          width: width,
          height: height,
        })
      }
    }

    if (
      !scoringHypnogramHeight.current ||
      chartWidthTotal !== 0 ||
      mainHeightTotal !== 0
    ) {
      updateDimensions()
    }

    const scoringHypnogramHeightObserver = new ResizeObserver(updateDimensions)

    if (scoringHypnogramHeight.current) {
      scoringHypnogramHeightObserver.observe(scoringHypnogramHeight.current)
    }

    window.addEventListener("resize", updateDimensions)

    return () => {
      window.removeEventListener("resize", updateDimensions)
      scoringHypnogramHeightObserver.disconnect()
    }
  }, [
    openInterpretationDrawerStatus,
    chartWidthTotal,
    scoringHypnogramHeight,
    mainHeightTotal,
    isGeneratingPDF,
    isLoading,
  ])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (editEventExclusionChangeStatus) {
        event.preventDefault()
        event.returnValue = ""
        return ""
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [editEventExclusionChangeStatus])

  useEffect(() => {
    return () => {
      resetGraphData()
      if (chartRef.current) {
        chartRef.current.innerHTML = ""
      }
    }
  }, [])

  useEffect(() => {
    if (!api || !studyID) return

    setPlotData(studyID)
  }, [api, studyID])

  useEffect(() => {
    if (!user || !study) return

    setDisableGraph(
      setChartStatus({ user, status: statusStrToEnum[study.status] })
    )
  }, [study, user])

  return (
    <Div
      sx={{
        padding: 0,
        border: 0,
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          p: 0,
          m: 0,
          paddingBottom: "5px",
        }}
        ref={scoringHypnogramHeight}
      >
        <StudyScoringHeader
          openInterpretationDrawer={openInterpretationDrawer}
          printSleepPdf={printSleepPdf}
          studyID={studyID}
          ref={headerHeight}
        ></StudyScoringHeader>
        <StyledDatasetHeader
          sx={{
            width:
              interpretationDrawerWidth > 0
                ? `calc(100% - ${
                    openInterpretationDrawerStatus
                      ? interpretationDrawerWidth
                      : 0
                  }px)`
                : "100%",
          }}
        >
          <Box
            sx={{
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              marginBottom: 3,
              marginX: 1,
            }}
          >
            <Stack
              direction="row"
              sx={{ m: 0 }}
              justifyContent={"space-between"}
              alignItems="center"
              spacing={2}
            >
              <ScoringHeader onOpenPlotOrderModal={handleOpenPlotOrderModal}>
                <></>
              </ScoringHeader>
            </Stack>
          </Box>
          <Stack direction="row" spacing={2} sx={{ m: 0 }} alignItems="top">
            <Box width="250px" sx={{ pt: 1 }}>
              <IntervalFilter />
            </Box>
            <ParentSize debounceTime={20}>
              {({ width, height }) => {
                return (
                  <HyponogramScroller
                    yDomainPaddingPercentage={20}
                    tickFormat={(_, n: number) =>
                      Object.keys(SleepStageMap)[n.valueOf()] ?? "?"
                    }
                    width={width - 10}
                    height={50}
                  />
                )
              }}
            </ParentSize>
          </Stack>
        </StyledDatasetHeader>
      </Box>
      <StyledStudyDatasetContent
        sx={{
          width:
            interpretationDrawerWidth > 0
              ? `calc(100% - ${
                  openInterpretationDrawerStatus ? interpretationDrawerWidth : 0
                }px)`
              : "100%",
        }}
      >
        {!isInvalidNumber(dimensions.height) && (
          <StackedPlots
            ref={chartRef}
            yScales={yScales}
            width={dimensions.width}
            height={dimensions.height}
            handleOpenScaleModal={handleOpenScaleModal}
          />
        )}
      </StyledStudyDatasetContent>

      <InterpretationDrawer
        initialInterpretationEditorContentData={
          initialInterpretationEditorContentData
        }
        interpretationDrawerWidth={interpretationDrawerWidth}
        openInterpretationDrawerStatus={openInterpretationDrawerStatus}
        setOpenInterpretationDrawerStatus={setOpenInterpretationDrawerStatus}
        openInterpretationTemplateModal={openInterpretationTemplateModal}
        onInterpretationEditorContentChange={
          onInterpretationEditorContentChange
        }
        setInterpretationDrawerWidth={setInterpretationDrawerWidth}
        openSignatureModal={openSignatureModal}
      />

      <Suspense fallback={null}>
        {!isInterpretationTemplateLoading &&
          interpretationTemplateModalStatus && (
            <InterpretationTemplateModal
              interpretationTemplateModalStatus={
                interpretationTemplateModalStatus
              }
              setInterpretationModalStatus={
                setInterpretationTemplateModalStatus
              }
              setInterpretationEditorContentData={
                setInterpretationEditorContentData
              }
              setInitialInterpretationContentData={
                setInitialInterpretationEditorContentData
              }
            />
          )}
      </Suspense>

      <Suspense fallback={null}>
        {signatureModalStatus && (
          <SignatureModal
            signatureUrl={signatureUrl}
            editSignature={editSignature}
            setEditSignature={setEditSignature}
            droppedFile={droppedFile}
            setDroppedFile={setDroppedFile}
            signatureModalStatus={signatureModalStatus}
            setSignatureModalStatus={setSignatureModalStatus}
            signatureRadioValue={signatureRadioValue}
            setSignatureRadioValue={setSignatureRadioValue}
            handleChange={handleChange}
            saveSignature={saveSignature}
            signatureRef={signatureRef}
          />
        )}
      </Suspense>

      <Suspense fallback={null}>
        {pdfUrlLink.length > 0 && previewPdfModalStatus && (
          <PreviewPdfModal
            previewPdfModalStatus={previewPdfModalStatus}
            setPreviewPdfModalStatus={setPreviewPdfModalStatus}
            interpretCurrentStudy={interpretCurrentStudy}
            studyInterpreting={studyInterpreting}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {scaleModalOpen && (
          <YScaleModal
            autoScaling={autoScaling}
            setAutoScaling={setAutoScaling}
            handleSaveScaleModal={handleSaveScaleModal}
            handleCloseScaleModal={handleCloseScaleModal}
            newYScales={newYScales}
            setNewYScales={setNewYScales}
            yScales={yScales}
            scaleModalOpen={scaleModalOpen}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {openPlotOrderModal && (
          <PlotOrderModal
            open={openPlotOrderModal}
            handleClose={() => setOpenPlotOrderModal(false)}
          />
        )}
      </Suspense>
      <Loaders
        loadingPreviewModal={loadingPreviewModal}
        interpretationTemplateModalStatus={interpretationTemplateModalStatus}
      />
    </Div>
  )
}

export default StudyScoringContent
