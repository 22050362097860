import { scaleLinear } from "@visx/scale"
import { Orientation } from "@visx/axis"
import React, { RefObject, forwardRef, ForwardedRef } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useEventContextMenu } from "./event/EventContextMenu"
import { StyledSvg } from "@huxley-medical/react-components/components"
import { useHandleArrowKeys } from "@huxley-medical/react-components/hooks/keyboard"
import { epochMs } from "../../constants"
import StackableLinePlot, { labelWidth, topPadding } from "./StackableLinePlot"
import throttle from "lodash.throttle"
import { useMouseWheel } from "@huxley-medical/react-components/hooks/mouse"
import { currentEpoch, numEpochs } from "../../state/epoch.state"
import { studyPlotOrder } from "../../state/study.state"
import { allPlotTimeDomain, studySignals } from "../../state/signals.state"
import {
  fullNightInterval,
  intervalTimeEventTypeAtom,
} from "../../state/study.state"
import { LinePlot } from "../../types/line.type"
import AxisGraph from "./Axis/AxisGraph"
import { EventPlot } from "../../types/event.type"
import { isGeneratingPDFReport } from "../../state/pdfReport.state"
import { Div } from "@huxley-medical/react-components/elements"

type ThrottledRightArrowHandlerParams = {
  totalEpochs: number
  setEpoch: (valOrUpdater: number | ((currVal: number) => number)) => void
  numEpochs: number
}

type ThrottledLeftArrowHandlerParams = {
  setEpoch: (valOrUpdater: number | ((currVal: number) => number)) => void
  numEpochs: number
}

type Scale = {
  minY: number
  maxY: number
}

export const numYTicksDefault = 5

/**
 * StackedPlots is the parent functional component of the individual stacked
 * plots. It handles scrolling epochs using keyboard arrow keys, the event
 * context menu, calculation of global time x-domain, and dataset epoch
 * pagination and slicing.
 *
 * @param {PlotsParams} plotsParams
 * @returns {JSX.Element} JSX.Element
 */
const StackedPlots = forwardRef(
  (
    props: {
      width: number
      height: number
      yScales: {
        Position: Scale
        SANSASleepStage: Scale
        SpO2: Scale
        HR: Scale
        Actigraphy: Scale
        Chest: Scale
        Resp: Scale
        Snoring: Scale
        ECG: Scale
      }
      handleOpenScaleModal: (plotType: EventPlot) => void
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { width, height, yScales, handleOpenScaleModal } = props
    const ContextMenu = useEventContextMenu({
      plotsRef: ref as RefObject<HTMLDivElement>,
    })
    const totalEpochs = useRecoilValue(numEpochs)
    const [epoch, setEpoch] = useRecoilState(currentEpoch)
    const plots = useRecoilValue(studySignals)
    const [xAllMin, xAllMax] = useRecoilValue(allPlotTimeDomain)
    const plotOrder = useRecoilValue(studyPlotOrder)
    const selectedTimeInterval = useRecoilValue(intervalTimeEventTypeAtom)
    const fullNightIntervalValue = useRecoilValue(fullNightInterval)
    const isGeneratingPDF = useRecoilValue(isGeneratingPDFReport)

    /**
     * throttledLeftArrowHandler is a throttled function for scrolling the current epoch backwards
     */
    const throttledLeftArrowHandler = throttle(
      ({ setEpoch, numEpochs }: ThrottledLeftArrowHandlerParams) => {
        setEpoch((current) => {
          const newEpoch = current - numEpochs
          if (newEpoch <= 0) {
            return 0
          } else {
            return newEpoch
          }
        })
      },
      200,
      { leading: true, trailing: false }
    )

    /**
     * throttledRightArrowHandler is a throttled function for scrolling the current epoch forwards
     */
    const throttledRightArrowHandler = //throttle(
      ({
        totalEpochs,
        setEpoch,
        numEpochs,
      }: ThrottledRightArrowHandlerParams) => {
        setEpoch((current) => {
          const newEpoch = current + numEpochs
          const lastScrollableEpoch =
            totalEpochs - selectedTimeInterval / epochMs
          if (newEpoch >= lastScrollableEpoch) {
            return lastScrollableEpoch
          } else {
            return newEpoch
          }
        })
      }
    //, { leading: true, trailing: false }
    //)

    // Setup event handlers that will update recoil state
    //const handleEventRemove = useRecoilCallback(eventRemove)

    // Handle changing epoch state when left or right arrow keys are pressed
    // NOTE: we could move the arrow key code to EpochScrollWindow
    // (Update range before epoch (without epoch deps))
    // to remove the fromLocal state...
    // Would require mouseup for useHandleArrowKeys

    //Define the scroll amount to be half the current selected time interval in epochs
    const scrollAmount = Math.floor(selectedTimeInterval / epochMs / 2)

    useHandleArrowKeys(
      {
        onLeftArrow: () =>
          throttledLeftArrowHandler({ setEpoch, numEpochs: scrollAmount }),
        onRightArrow: () =>
          throttledRightArrowHandler({
            totalEpochs,
            setEpoch,
            numEpochs: scrollAmount,
          }),
      },
      [totalEpochs, scrollAmount]
    )

    useMouseWheel(
      {
        handler: (e) => {
          if (fullNightIntervalValue === selectedTimeInterval) return
          // 13 is the deltaY for a single scroll "tick"

          //const mouseScrollEpochs = scrollAmount

          if (e.deltaY < 0) {
            throttledRightArrowHandler({
              totalEpochs,
              setEpoch,
              numEpochs: scrollAmount,
            })
          } else {
            throttledLeftArrowHandler({
              setEpoch,
              numEpochs: scrollAmount,
            })
          }
        },
        activated: true,
      },
      [totalEpochs, fullNightIntervalValue, selectedTimeInterval, scrollAmount]
    )

    /*useHandleDeleteKey(
    {
      onDeleteKeyPress: handleEventRemove(selectedEvent, studyID, useRecoilValue(eventFamily(selectedEvent))),
      activated: true,
    },
    [selectedEvent]
  )*/

    // Caclulate page & data slicing given epoch state
    const epochOffset = epoch * epochMs

    // Slice data here based on page state
    const xPageStart =
      xAllMin + epochOffset + selectedTimeInterval > xAllMax
        ? xAllMax - selectedTimeInterval
        : xAllMin + epochOffset

    // Page end will change as per selected time interval
    const xPageEnd =
      xPageStart + selectedTimeInterval > xAllMax
        ? xAllMax
        : xPageStart + selectedTimeInterval

    // epochPageMs
    const tickIntervals =
      Math.floor(
        selectedTimeInterval === fullNightIntervalValue
          ? xAllMax - xAllMin
          : selectedTimeInterval
      ) * 0.1
    const orderedPlots: LinePlot[] =
      plotOrder?.map((plot) => {
        return plots.find((p) => p.plotType === plot.plotType) as LinePlot
      }) || []

    const enabledPlots = orderedPlots.filter(
      (plot) =>
        plotOrder.find((p) => p.plotType === plot.plotType)?.enabled &&
        plot.signalLength > 0
    )

    //const topHeaderHeight = 20
    const plotHeight = height - topPadding
    const plotWidth = width - labelWidth

    // define time scale for this page
    const timeScale = scaleLinear<number>({
      domain: [xPageStart, xPageEnd],
      range: [0, plotWidth],
    })
    const graphPlotHeight = isGeneratingPDF
      ? height / (enabledPlots.length + 1)
      : plotHeight / enabledPlots.length < 55
      ? 55
      : plotHeight / (enabledPlots.length + 0.45)

    const chartPlotHeight = isGeneratingPDF
      ? height / enabledPlots.length - 0.7
      : plotHeight / (enabledPlots.length - 0.7) < 55
      ? 55
      : plotHeight / (enabledPlots.length - 0.55)

    const stackedPlotHeight = chartPlotHeight * enabledPlots.length + 20
    return (
      <Div ref={ref} style={{ width: width + 20, height: stackedPlotHeight }}>
        <StyledSvg
          width={width + 20}
          viewBox={`0 0 ${width + 20} ${stackedPlotHeight}`}
          height={stackedPlotHeight}
        >
          {plotWidth > 8 &&
            enabledPlots.map(
              (
                {
                  data,
                  title,
                  plotType,
                  lineColor = "#000",
                  yTickValues,
                  yTickFormatter,
                  yDomainPadding,
                  unit,
                  plotLine = "line",
                  signalLength,
                  signalMaxValue,
                  signalMinValue,
                },
                i
              ) => {
                return (
                  <React.Fragment key={i + title}>
                    {width > 8 && signalLength > 0 && (
                      <StackableLinePlot
                        key={title}
                        inx={i}
                        title={title}
                        signalData={data}
                        timeScale={timeScale}
                        lineColor={lineColor}
                        lineHeight={graphPlotHeight}
                        width={plotWidth}
                        tickIntervals={tickIntervals}
                        plotType={plotType}
                        plotWidth={plotWidth}
                        yTicks={yTickValues}
                        yTickFormat={yTickFormatter}
                        unit={unit}
                        plotLine={plotLine}
                        xPageStart={xPageStart}
                        xPageEnd={xPageEnd}
                        yScales={yScales}
                        yDomainPadding={yDomainPadding}
                        handleOpenScaleModal={() =>
                          handleOpenScaleModal(plotType)
                        }
                        signalLength={signalLength}
                        signalMaxValue={signalMaxValue}
                        signalMinValue={signalMinValue}
                      />
                    )}
                  </React.Fragment>
                )
              }
            )}

          {width > 0 && (
            <AxisGraph
              top={topPadding}
              left={labelWidth}
              hideTicks={true}
              // Shift tick numbers to the left
              tickTransform="translate(-5, 0)"
              // Don't hide entire axis, this fixes a bottom plot line
              // zindex as side-effect
              minTick={xPageStart}
              maxTick={xPageEnd}
              tickInterval={tickIntervals}
              orientation={Orientation.top}
              scale={timeScale}
              tickFormat={() => {
                return ``
              }}
            />
          )}

          {width > 0 && (
            <AxisGraph
              top={graphPlotHeight * enabledPlots.length}
              axisClassName="bottom-axis-time-stamp"
              left={labelWidth}
              hideTicks={true}
              // Shift tick numbers to the left
              tickTransform="translate(-10, 0)"
              // Don't hide entire axis, this fixes a bottom plot line
              // zindex as side-effect
              minTick={xPageStart}
              maxTick={xPageEnd}
              tickInterval={tickIntervals}
              orientation={undefined}
              scale={timeScale}
              tickFormat={(time) => {
                return new Date((time as number) * 1000).toLocaleTimeString()
              }}
            />
          )}
        </StyledSvg>
        <ContextMenu />
      </Div>
    )
  }
)
StackedPlots.displayName = "StackedPlots"
export default StackedPlots
