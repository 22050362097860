/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepStudyPatientOut
 */
export interface SleepStudyPatientOut {
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyPatientOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    mrn: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    primaryPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    secondaryPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyPatientOut
     */
    dob: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    race: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    sex: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    referringPhysicianFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    referringPhysicianLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    referringPhysicianPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    height?: string;
    /**
     * 
     * @type {number}
     * @memberof SleepStudyPatientOut
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    referringPhysician: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyPatientOut
     */
    organizationId: string;
}

/**
 * Check if a given object implements the SleepStudyPatientOut interface.
 */
export function instanceOfSleepStudyPatientOut(value: object): value is SleepStudyPatientOut {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('mrn' in value) || value['mrn'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('dob' in value) || value['dob'] === undefined) return false;
    if (!('race' in value) || value['race'] === undefined) return false;
    if (!('sex' in value) || value['sex'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('referringPhysician' in value) || value['referringPhysician'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    return true;
}

export function SleepStudyPatientOutFromJSON(json: any): SleepStudyPatientOut {
    return SleepStudyPatientOutFromJSONTyped(json, false);
}

export function SleepStudyPatientOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyPatientOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'firstName': json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'],
        'mrn': json['mrn'],
        'email': json['email'],
        'primaryPhone': json['primary_phone'] == null ? undefined : json['primary_phone'],
        'secondaryPhone': json['secondary_phone'] == null ? undefined : json['secondary_phone'],
        'dob': (new Date(json['dob'])),
        'race': json['race'],
        'sex': json['sex'],
        'referringPhysicianFirstName': json['referring_physician_first_name'] == null ? undefined : json['referring_physician_first_name'],
        'referringPhysicianLastName': json['referring_physician_last_name'] == null ? undefined : json['referring_physician_last_name'],
        'referringPhysicianPhone': json['referring_physician_phone'] == null ? undefined : json['referring_physician_phone'],
        'height': json['height'] == null ? undefined : json['height'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'name': json['name'],
        'referringPhysician': json['referring_physician'],
        'organizationId': json['organization_id'],
    };
}

export function SleepStudyPatientOutToJSON(value?: SleepStudyPatientOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'mrn': value['mrn'],
        'email': value['email'],
        'primary_phone': value['primaryPhone'],
        'secondary_phone': value['secondaryPhone'],
        'dob': ((value['dob']).toISOString().substring(0,10)),
        'race': value['race'],
        'sex': value['sex'],
        'referring_physician_first_name': value['referringPhysicianFirstName'],
        'referring_physician_last_name': value['referringPhysicianLastName'],
        'referring_physician_phone': value['referringPhysicianPhone'],
        'height': value['height'],
        'weight': value['weight'],
        'name': value['name'],
        'referring_physician': value['referringPhysician'],
        'organization_id': value['organizationId'],
    };
}

