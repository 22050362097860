import { Input, Option, Select } from "@mui/joy"
import PhoneInput, { Value } from "react-phone-number-input/input"
import { OrganizationOut, PatientIn } from "../../generated/fetchclient"
import {
  patientRaceOptions,
  patientSexOptions,
} from "../../const/patient.const"
import GridDivider from "../griddivider/GridDivider"
import GridFormInput from "../forms/grid/GridFormInput"

interface PatientFormFieldsProps {
  patientInput: PatientIn
  inputErrors: {
    firstName: string
    lastName: string
    email: string
    primaryPhone: string
    referringPhysicianPhone: string
  }
  handlePropChange: (
    property: keyof PatientIn,
    value: Date | string | Value
  ) => void
  showOrganizationField?: boolean
  organizations?: OrganizationOut[]
}

function PatientFormFields({
  patientInput,
  inputErrors,
  handlePropChange,
  showOrganizationField = false,
  organizations = [],
}: PatientFormFieldsProps) {
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <GridDivider
        xs={12}
        sx={{ "--Divider-childPosition": "0%" }}
        text="Patient Information"
      />
      <GridFormInput
        width={4}
        label="First Name *"
        error={inputErrors.firstName}
      >
        <Input
          value={patientInput.firstName}
          onChange={(e) => handlePropChange("firstName", e.target.value)}
          required
        />
      </GridFormInput>
      <GridFormInput width={4} label="Middle Name">
        <Input
          value={patientInput.middleName}
          onChange={(e) => handlePropChange("middleName", e.target.value)}
        />
      </GridFormInput>
      <GridFormInput width={4} label="Last Name *" error={inputErrors.lastName}>
        <Input
          value={patientInput.lastName}
          onChange={(e) => handlePropChange("lastName", e.target.value)}
          required
        />
      </GridFormInput>
      <GridFormInput
        width={6}
        label="Phone Number"
        error={inputErrors.primaryPhone}
      >
        <PhoneInput
          className={"phone-input " + (inputErrors.primaryPhone ? "error" : "")}
          country="US"
          value={patientInput.primaryPhone as Value}
          onChange={(value) => handlePropChange("primaryPhone", value || "")}
        />
      </GridFormInput>
      <GridFormInput width={6} label="Email Address" error={inputErrors.email}>
        <Input
          type="email"
          error={!!inputErrors.email}
          value={patientInput.email}
          onChange={(e) => handlePropChange("email", e.target.value)}
        />
      </GridFormInput>
      <GridFormInput width={6} label="Date of Birth *">
        <Input
          type="date"
          value={patientInput.dob.toISOString().split("T")[0]}
          onChange={(e) => {
            const dob = e.target.valueAsDate
            if (dob) {
              handlePropChange("dob", dob)
            }
          }}
          slotProps={{
            input: {
              max: new Date().toISOString().split("T")[0],
            },
          }}
          required
        />
      </GridFormInput>
      <GridFormInput width={6} label="MRN *">
        <Input
          value={patientInput.mrn}
          onChange={(e) => handlePropChange("mrn", e.target.value)}
          required
        />
      </GridFormInput>
      <GridFormInput width={6} label="Race *">
        <Select
          value={patientInput.race}
          onChange={(_, value) => {
            if (value) {
              handlePropChange("race", value)
            }
          }}
          placeholder="Select one"
          required
        >
          {patientRaceOptions.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </GridFormInput>
      <GridFormInput width={6} label="Sex *">
        <Select
          value={patientInput.sex}
          onChange={(_, value) => {
            if (value) {
              handlePropChange("sex", value)
            }
          }}
          placeholder="Select one"
          required
        >
          {patientSexOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </GridFormInput>
      <GridFormInput width={6} label="Referring Physician First Name">
        <Input
          value={patientInput.referringPhysicianFirstName}
          onChange={(e) =>
            handlePropChange("referringPhysicianFirstName", e.target.value)
          }
        />
      </GridFormInput>
      <GridFormInput width={6} label="Referring Physician Last Name">
        <Input
          value={patientInput.referringPhysicianLastName}
          onChange={(e) =>
            handlePropChange("referringPhysicianLastName", e.target.value)
          }
        />
      </GridFormInput>
      <GridFormInput
        width={6}
        label="Referring Physician Phone Number"
        error={inputErrors.referringPhysicianPhone}
      >
        <PhoneInput
          className={
            "phone-input " +
            (inputErrors.referringPhysicianPhone ? "error" : "")
          }
          country="US"
          value={patientInput.referringPhysicianPhone as Value}
          onChange={(value) => {
            handlePropChange("referringPhysicianPhone", value || "")
          }}
        />
      </GridFormInput>
      {showOrganizationField && (
        <GridFormInput width={6} label="Organization">
          <Select
            value={patientInput.organizationId}
            onChange={(_, value) => {
              if (value !== null) {
                handlePropChange("organizationId", value)
              }
            }}
            placeholder="Select one"
          >
            {organizations.map((option, index) => (
              <Option key={index + 1} value={option.uuid}>
                {option.name}
              </Option>
            ))}
          </Select>
        </GridFormInput>
      )}
    </>
  )
}

export default PatientFormFields
