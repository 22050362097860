import { phone } from "phone"
import * as EmailValidator from "email-validator"
import { PatientIn } from "../generated/fetchclient"

interface PatientFormErrors {
  firstName: string
  lastName: string
  primaryPhone: string
  email: string
  referringPhysicianPhone: string
}

export const validatePatientForm = (
  patientInput: PatientIn,
  setInputErrors: React.Dispatch<React.SetStateAction<PatientFormErrors>>
) => {
  let primaryPhoneError = ""
  let emailError = ""
  let referringPhysicianPhoneError = ""
  let patientFirstNameError = ""
  let patientLastNameError = ""

  if (patientInput.firstName.trim() === "") {
    patientFirstNameError = "Required"
  }

  if (patientInput.lastName.trim() === "") {
    patientLastNameError = "Required"
  }

  if (
    patientInput.primaryPhone?.length &&
    !phone(patientInput.primaryPhone).isValid
  ) {
    primaryPhoneError = "Invalid phone number"
  }

  if (
    patientInput.email?.trim() &&
    !EmailValidator.validate(patientInput.email)
  ) {
    emailError = "Invalid email address"
  }

  if (
    patientInput.referringPhysicianPhone &&
    patientInput.referringPhysicianPhone !== "" &&
    !phone(patientInput.referringPhysicianPhone).isValid
  ) {
    referringPhysicianPhoneError = "Invalid phone number"
  }

  const errors: PatientFormErrors = {
    firstName: patientFirstNameError,
    lastName: patientLastNameError,
    primaryPhone: primaryPhoneError,
    email: emailError,
    referringPhysicianPhone: referringPhysicianPhoneError,
  }

  setInputErrors(errors)

  return Object.values(errors).every((e) => e === "")
}
