import Button from "@mui/joy/Button"
import Divider from "@mui/joy/Divider"
import Grid from "@mui/joy/Grid"
import Radio from "@mui/joy/Radio"
import RadioGroup from "@mui/joy/RadioGroup"
import { useState } from "react"
import { Value } from "react-phone-number-input/input"
import { useSetRecoilState } from "recoil"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import { PatientIn } from "../../../generated/fetchclient"
import GridDivider from "../../griddivider/GridDivider"
import PatientSelectTable from "./PatientSelectTable"
import { handleApiError } from "../../../utils/apiUtils"
import { PatientIdentifier } from "../../../types/patient.type"
import PatientFormFields from "../../patient/PatientFormFields"
import { validatePatientForm } from "../../../utils/validateUtils"

type PatientSelectFormParams = {
  selectedPatient: PatientIdentifier
  setPage: React.Dispatch<React.SetStateAction<number>>
  setSelectedPatient: React.Dispatch<React.SetStateAction<PatientIdentifier>>
}

const emptyPatientInput = {
  firstName: "",
  lastName: "",
  email: "",
  dob: new Date(),
  mrn: "",
  race: "",
  sex: "",
}

const emptyInputErrors = {
  firstName: "",
  lastName: "",
  primaryPhone: "",
  email: "",
  referringPhysicianPhone: "",
}

function PatientSelectForm({
  selectedPatient,
  setPage,
  setSelectedPatient,
}: PatientSelectFormParams) {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [useExistingPatient, setUseExistingPatient] = useState(false)
  const [patientInput, setPatientInput] = useState<PatientIn>(emptyPatientInput)
  const [inputErrors, setInputErrors] = useState(emptyInputErrors)

  const handlePropChange = (
    property: keyof PatientIn,
    value: Date | string | Value
  ) => {
    setPatientInput({
      ...patientInput,
      [property]: value,
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (useExistingPatient) {
      setPage(2)
      return
    }

    if (!validatePatientForm(patientInput, setInputErrors)) {
      return
    }

    try {
      const newPatient = await api.createPatient({
        patientIn: patientInput,
      })

      setSnackAlertMsg({
        open: true,
        message: "Patient created successfully",
        severity: "success",
        autoHideDuration: 5000,
      })

      const { uuid, firstName, middleName, lastName } = newPatient
      const name = middleName
        ? `${firstName} ${middleName} ${lastName}`
        : `${firstName} ${lastName}`
      setSelectedPatient({
        uuid: uuid || "",
        name,
      })
      setPage(2)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid xs={12} display="flex" justifyContent="center">
          <RadioGroup orientation="horizontal">
            <Radio
              label="New Patient"
              checked={!useExistingPatient}
              onClick={() => setUseExistingPatient(false)}
            />
            <Radio
              label="Existing Patient"
              checked={useExistingPatient}
              onClick={() => setUseExistingPatient(true)}
            />
          </RadioGroup>
        </Grid>

        {useExistingPatient ? (
          <>
            <GridDivider
              xs={12}
              sx={{ "--Divider-childPosition": "0%" }}
              text="Existing Patient Search"
            />
            <Grid xs={12}>
              <PatientSelectTable setSelectedPatient={setSelectedPatient} />
            </Grid>
          </>
        ) : (
          <PatientFormFields
            patientInput={patientInput}
            inputErrors={inputErrors}
            handlePropChange={handlePropChange}
          />
        )}
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            disabled={useExistingPatient && !selectedPatient?.uuid}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PatientSelectForm
