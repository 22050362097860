import { useTheme } from "@mui/joy"
import { TickRendererProps } from "@visx/axis"

interface CustomTickProps extends TickRendererProps {
  isLastTick: boolean
  isSmallScreen: boolean
}

const CustomTick: React.FC<CustomTickProps> = ({
  x,
  y,
  formattedValue,
  isLastTick,
  isSmallScreen,
}) => {
  const theme = useTheme()
  const textColor = theme.palette.text.primary

  return (
    <text
      x={isLastTick && !isSmallScreen ? x - 10 : x} // Move the last tick slightly left
      y={y}
      textAnchor="middle"
      dy="0.1em"
      fill={textColor}
      fontSize={10}
    >
      {formattedValue}
    </text>
  )
}

export default CustomTick
