/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActiveSleepStudyOrderOut } from './ActiveSleepStudyOrderOut';
import {
    ActiveSleepStudyOrderOutFromJSON,
    ActiveSleepStudyOrderOutFromJSONTyped,
    ActiveSleepStudyOrderOutToJSON,
} from './ActiveSleepStudyOrderOut';
import type { SleepStudyOrderOutBase } from './SleepStudyOrderOutBase';
import {
    SleepStudyOrderOutBaseFromJSON,
    SleepStudyOrderOutBaseFromJSONTyped,
    SleepStudyOrderOutBaseToJSON,
} from './SleepStudyOrderOutBase';

/**
 * 
 * @export
 * @interface PatientOut
 */
export interface PatientOut {
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatientOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    organization: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    mrn: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    primaryPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    secondaryPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatientOut
     */
    dob: Date;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    race: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    sex: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    referringPhysicianFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    referringPhysicianLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    referringPhysicianPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    height?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientOut
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientOut
     */
    fullName: string;
    /**
     * 
     * @type {Array<SleepStudyOrderOutBase>}
     * @memberof PatientOut
     */
    studyOrders: Array<SleepStudyOrderOutBase>;
    /**
     * 
     * @type {ActiveSleepStudyOrderOut}
     * @memberof PatientOut
     */
    activeStudyOrder?: ActiveSleepStudyOrderOut;
}

/**
 * Check if a given object implements the PatientOut interface.
 */
export function instanceOfPatientOut(value: object): value is PatientOut {
    if (!('organization' in value) || value['organization'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('mrn' in value) || value['mrn'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('dob' in value) || value['dob'] === undefined) return false;
    if (!('race' in value) || value['race'] === undefined) return false;
    if (!('sex' in value) || value['sex'] === undefined) return false;
    if (!('fullName' in value) || value['fullName'] === undefined) return false;
    if (!('studyOrders' in value) || value['studyOrders'] === undefined) return false;
    return true;
}

export function PatientOutFromJSON(json: any): PatientOut {
    return PatientOutFromJSONTyped(json, false);
}

export function PatientOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'organization': json['organization'],
        'firstName': json['first_name'],
        'middleName': json['middle_name'] == null ? undefined : json['middle_name'],
        'lastName': json['last_name'],
        'mrn': json['mrn'],
        'email': json['email'],
        'primaryPhone': json['primary_phone'] == null ? undefined : json['primary_phone'],
        'secondaryPhone': json['secondary_phone'] == null ? undefined : json['secondary_phone'],
        'dob': (new Date(json['dob'])),
        'race': json['race'],
        'sex': json['sex'],
        'referringPhysicianFirstName': json['referring_physician_first_name'] == null ? undefined : json['referring_physician_first_name'],
        'referringPhysicianLastName': json['referring_physician_last_name'] == null ? undefined : json['referring_physician_last_name'],
        'referringPhysicianPhone': json['referring_physician_phone'] == null ? undefined : json['referring_physician_phone'],
        'height': json['height'] == null ? undefined : json['height'],
        'weight': json['weight'] == null ? undefined : json['weight'],
        'fullName': json['full_name'],
        'studyOrders': ((json['study_orders'] as Array<any>).map(SleepStudyOrderOutBaseFromJSON)),
        'activeStudyOrder': json['active_study_order'] == null ? undefined : ActiveSleepStudyOrderOutFromJSON(json['active_study_order']),
    };
}

export function PatientOutToJSON(value?: PatientOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'organization': value['organization'],
        'first_name': value['firstName'],
        'middle_name': value['middleName'],
        'last_name': value['lastName'],
        'mrn': value['mrn'],
        'email': value['email'],
        'primary_phone': value['primaryPhone'],
        'secondary_phone': value['secondaryPhone'],
        'dob': ((value['dob']).toISOString().substring(0,10)),
        'race': value['race'],
        'sex': value['sex'],
        'referring_physician_first_name': value['referringPhysicianFirstName'],
        'referring_physician_last_name': value['referringPhysicianLastName'],
        'referring_physician_phone': value['referringPhysicianPhone'],
        'height': value['height'],
        'weight': value['weight'],
        'full_name': value['fullName'],
        'study_orders': ((value['studyOrders'] as Array<any>).map(SleepStudyOrderOutBaseToJSON)),
        'active_study_order': ActiveSleepStudyOrderOutToJSON(value['activeStudyOrder']),
    };
}

