import { ResponseError } from "../generated/fetchclient/runtime"
import { SetterOrUpdater } from "recoil"
import { SnackAlert } from "../types/snackAlert.types"

export function handleApiError(fn: SetterOrUpdater<SnackAlert>) {
  return async (error: unknown, customExtraMsg?: string) => {
    let message = "An API error occurred."
    try {
      const response = await (error as ResponseError).response.json()
      if (typeof response.detail === "string") {
        message = response.detail
      } else {
        console.error("Error detail:", response.detail)
      }
      // eslint-disable-next-line prettier/prettier
    } catch (e) {}

    if (customExtraMsg) {
      message += customExtraMsg
    }

    fn({
      open: true,
      message,
      severity: "error",
      autoHideDuration: 5000,
    })
  }
}
