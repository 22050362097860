import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import {
  GetAllOrganizationsRequest,
  OrganizationIn,
} from "../generated/fetchclient"
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil"
import { organizationCount, organizations } from "../state/organization.state"
import { handleApiError } from "../utils/apiUtils"
import { OrganizationTableRow } from "../types/table.type"

const useOrganizations = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setOrganizations = useSetRecoilState(organizations)
  const setOrganizationCount = useSetRecoilState(organizationCount)
  const resetOrganizations = useResetRecoilState(organizations)
  const resetOrganizationCount = useResetRecoilState(organizationCount)

  /**
   * Creates a new organization.
   *
   * @param organization The details of the organization to create.
   */
  const createOrganization = async (organization: OrganizationIn) => {
    if (api === undefined) return

    try {
      await api.createOrganization({ organizationIn: organization })
      setSnackAlertMsg({
        open: true,
        message: "Organization Created",
        severity: "success",
        autoHideDuration: 5000,
      })
      listOrganizations()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      throw error
    }
  }

  /**
   * Deletes an organization by its ID.
   *
   * @param organizationId The ID of the organization to delete.
   */
  const deleteOrganization = async (organizationId: string) => {
    if (api === undefined) return

    try {
      await api.deleteOrganization({ organizationId })
      setSnackAlertMsg({
        open: true,
        message: "Organization Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      listOrganizations()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Restore an organization by its ID.
   *
   * @param organizationId The ID of the organization to update.
   */
  const restoreOrganization = async (organizationId: string) => {
    if (api === undefined) return

    try {
      await api.restoreOrganization({
        organizationId: organizationId,
      })
      setSnackAlertMsg({
        open: true,
        message: "Organization Restored",
        severity: "success",
        autoHideDuration: 5000,
      })
      listOrganizations()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists organizations.
   *
   * @param query The query to filter the list of organizations.
   */
  const listOrganizations = async (query: GetAllOrganizationsRequest = {}) => {
    if (api === undefined) return

    try {
      const organizations = await api.getAllOrganizations(query)
      setOrganizations(organizations.items)
      setOrganizationCount(organizations.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Get an organization by its ID.
   *
   * @param organizationId The ID of the organization to get.
   */
  const getOrganization = async (organizationId: string) => {
    if (api === undefined) return

    try {
      const organization = await api.getOrganization({ organizationId })
      return organization
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Update organizations.
   *
   * @param organizationId The ID of the organization to update.
   * @param organization updated organization data
   */
  const updateOrganization = async (
    organizationId: string,
    organization: OrganizationTableRow
  ) => {
    if (api === undefined) return
    try {
      const updatedOrganization = await api.updateOrganization({
        organizationId,
        organizationUpdate: organization,
      })
      setOrganizations((organizations) => {
        const allOrganizations = [...organizations]
        allOrganizations[organization.index as number] = updatedOrganization
        return allOrganizations
      })
      setSnackAlertMsg({
        open: true,
        message: "Organization Updated",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const resetOrganizationData = () => {
    resetOrganizations()
    resetOrganizationCount()
  }

  return {
    organizations: useRecoilValue(organizations),
    organizationCount: useRecoilValue(organizationCount),
    createOrganization,
    deleteOrganization,
    listOrganizations,
    resetOrganizationData,
    restoreOrganization,
    updateOrganization,
    getOrganization,
  }
}

export default useOrganizations
