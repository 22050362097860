import { useRecoilValue } from "recoil"
import DOMPurify from "dompurify"
import { JSONContent } from "@tiptap/core"
import parse from "html-react-parser"
import PdfTemplate from "./templates/PdfTemplate"
import { currentStudy, studyMetaData3 } from "../../state/study.state"
import { generateHTMLFromJsonContent } from "../../utils/tipTapUtils"
import PdfClinicianSignature from "./templates/PdfClinicianSignature"
import { FC } from "react"
import { OrganizationOut } from "../../generated/fetchclient"

const PdfInterpretationNotes: FC<{
  interpretationEditorContentData: JSONContent
  logo: string
  organization?: OrganizationOut
}> = ({ interpretationEditorContentData, organization, logo }) => {
  const study = useRecoilValue(currentStudy)
  const studyMetaDataValue = useRecoilValue(studyMetaData3)

  const pdfTemplateData = {
    study_id: study?.id,
    sw_version: studyMetaDataValue.swVersion,
  }
  const pdfNotes = DOMPurify.sanitize(
    generateHTMLFromJsonContent(interpretationEditorContentData, [])
  )

  return (
    <PdfTemplate
      study_id={pdfTemplateData.study_id}
      organization={organization}
      logo={logo}
      sw_version={pdfTemplateData.sw_version}
    >
      <div className="section-header">Interpretation Notes</div>
      <div className="pdf-interpretation-content" id="pdf-interpretation-notes">
        <div>{parse(pdfNotes)}</div>
      </div>
      <PdfClinicianSignature />
    </PdfTemplate>
  )
}

export default PdfInterpretationNotes
