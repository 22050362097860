/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserOut } from './UserOut';
import {
    UserOutFromJSON,
    UserOutFromJSONTyped,
    UserOutToJSON,
} from './UserOut';
import type { OrganizationLocationOut } from './OrganizationLocationOut';
import {
    OrganizationLocationOutFromJSON,
    OrganizationLocationOutFromJSONTyped,
    OrganizationLocationOutToJSON,
} from './OrganizationLocationOut';

/**
 * 
 * @export
 * @interface OrganizationOut
 */
export interface OrganizationOut {
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOut
     */
    logoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOut
     */
    enabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationOut
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Array<OrganizationLocationOut>}
     * @memberof OrganizationOut
     */
    locations: Array<OrganizationLocationOut>;
    /**
     * 
     * @type {Array<UserOut>}
     * @memberof OrganizationOut
     */
    users: Array<UserOut>;
}

/**
 * Check if a given object implements the OrganizationOut interface.
 */
export function instanceOfOrganizationOut(value: object): value is OrganizationOut {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('contactName' in value) || value['contactName'] === undefined) return false;
    if (!('contactEmail' in value) || value['contactEmail'] === undefined) return false;
    if (!('contactPhone' in value) || value['contactPhone'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('locations' in value) || value['locations'] === undefined) return false;
    if (!('users' in value) || value['users'] === undefined) return false;
    return true;
}

export function OrganizationOutFromJSON(json: any): OrganizationOut {
    return OrganizationOutFromJSONTyped(json, false);
}

export function OrganizationOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'contactName': json['contact_name'],
        'contactEmail': json['contact_email'],
        'contactPhone': json['contact_phone'],
        'logoUrl': json['logo_url'] == null ? undefined : json['logo_url'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'createdAt': (new Date(json['created_at'])),
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'locations': ((json['locations'] as Array<any>).map(OrganizationLocationOutFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserOutFromJSON)),
    };
}

export function OrganizationOutToJSON(value?: OrganizationOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'description': value['description'],
        'contact_name': value['contactName'],
        'contact_email': value['contactEmail'],
        'contact_phone': value['contactPhone'],
        'logo_url': value['logoUrl'],
        'enabled': value['enabled'],
        'created_at': ((value['createdAt']).toISOString()),
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'locations': ((value['locations'] as Array<any>).map(OrganizationLocationOutToJSON)),
        'users': ((value['users'] as Array<any>).map(UserOutToJSON)),
    };
}

