import { useState } from "react"
import { useSetRecoilState } from "recoil"
import Box from "@mui/joy/Box"
import CircularProgress from "@mui/joy/CircularProgress"
import Typography from "@mui/joy/Typography"
import Stack from "@mui/joy/Stack"
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import ReplayIcon from "@mui/icons-material/Replay"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import { useUploadQueue } from "../../hooks"
import { studyToBeUploaded } from "../../state/modal.state"

const UploadsStatusBar = () => {
  const [isHovered, setIsHovered] = useState(false)
  const setStudyToBeUploaded = useSetRecoilState(studyToBeUploaded)

  const { queue, removeUploadTask } = useUploadQueue()

  const inProgressOrFailed = queue.filter(
    (task) => task.status === "uploading" || task.status === "failed"
  )

  const openUploadModal = (id: string, readableId: string) => {
    setStudyToBeUploaded({
      id,
      readableId,
    })
    removeUploadTask(id)
  }

  if (inProgressOrFailed.length === 0) return null

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 82,
        right: 16,
        cursor: "pointer",
        borderRadius: 8,
        backgroundColor: "white",
        border: "0.3px solid",
        borderColor: "neutral.outlinedBorder",
        boxShadow: "lg",
        transition: "all 0.3s ease-in-out",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          sx={{
            p: 1,
          }}
        >
          {inProgressOrFailed.map((item) => {
            const uploadFailed = item.status === "failed"
            return (
              <Stack
                direction="row"
                key={item.id}
                spacing={1}
                alignItems="center"
              >
                {uploadFailed ? (
                  <ErrorOutlineIcon sx={{ color: "#E47474" }} />
                ) : (
                  <InsertDriveFileOutlinedIcon color="disabled" />
                )}

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    width: 120,
                  }}
                >
                  <Typography
                    level="body-sm"
                    noWrap
                    sx={{
                      color: "neutral.500",
                    }}
                  >
                    Study: {item.readableId}
                  </Typography>
                  {uploadFailed && (
                    <IconButton
                      onClick={() => openUploadModal(item.id, item.readableId)}
                      sx={{ padding: 0 }}
                    >
                      <ReplayIcon color="disabled" />
                    </IconButton>
                  )}
                </Stack>

                {uploadFailed ? (
                  <IconButton
                    onClick={() => removeUploadTask(item.id)}
                    sx={{ padding: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <CircularProgress thickness={3} size={"sm"} />
                )}
              </Stack>
            )
          })}
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          sx={{
            p: 0.5,
          }}
        >
          <CircularProgress thickness={5} />
        </Stack>
      )}
    </Box>
  )
}

export default UploadsStatusBar
