import { useState } from "react"
import { emptySelectedPatient } from "../../../components/study/newstudymodal/NewStudyModal"
import { PatientIdentifier } from "../../../types/patient.type"
import { EditModalStudyData } from "../../../types/event.type"
import PatientSelectForm from "./PatientSelectForm"
import StudyDetailForm from "./StudyDetailForm"
import { GetAllStudyOrdersRequest } from "../../../generated/fetchclient"

type StudyOrderFormParams = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  studyQuery?: GetAllStudyOrdersRequest
  patientData?: PatientIdentifier
  studyData?: EditModalStudyData
}

function StudyOrderForm({
  setOpen,
  page,
  setPage,
  patientData,
  studyData,
  studyQuery,
}: StudyOrderFormParams) {
  const [selectedPatient, setSelectedPatient] = useState<PatientIdentifier>(
    patientData !== undefined && Object.keys(patientData).length > 0
      ? patientData
      : emptySelectedPatient
  )
  return page === 1 ? (
    <PatientSelectForm
      selectedPatient={selectedPatient}
      setPage={setPage}
      setSelectedPatient={setSelectedPatient}
    />
  ) : (
    <StudyDetailForm
      selectedPatient={selectedPatient}
      studyData={studyData}
      setPage={setPage}
      setOpen={setOpen}
      studyQuery={studyQuery || {}}
    />
  )
}

export default StudyOrderForm
