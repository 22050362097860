import { useRecoilCallback, useRecoilValue } from "recoil"
import { Group } from "@visx/group"
import CancelIcon from "@mui/icons-material/Cancel"
import { useRef } from "react"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { ExclusionAnnotation } from "../../../types/exclusion.type"
import { currentUser } from "../../../state/auth.state"
import { disableGraph } from "../../../state/graph.state"
import {
  activeCriteria,
  fullNightInterval,
  intervalTimeEventTypeAtom,
} from "../../../state/study.state"
import { StyledExclusionGroup } from "../../../styled/Group"
import { StyledCircle } from "../../../styled/Circle"
import { StyledMoveText } from "../../../styled/Text"
import { ScaleLinear } from "d3-scale"
import { DetectionFlags, EventPlot } from "../../../types/event.type"
import { styled } from "@mui/joy/styles"
import { Bar } from "@visx/shape"
import { intToSpo2Flag } from "../../../utils/eventUtils"
import { exclusionRemove } from "../../../utils/exclusionUtils"

export const StyledExclusion = styled(Bar)({
  stroke: "#2c2c2c02",
  strokeWidth: ".5px",
})

/**
 * Event represents the semi-transparent w/gradient event windows that are
 * drawn on plots. Can be dragged and resized by user. Also contains
 * context menu used to change event type or delete.
 *
 * @param {ExclusionParams} ExclusionParams
 * @returns {JSX.Element} JSX.Element
 */

type ExclusionParams = {
  height: number
  timeScale: ScaleLinear<number, number, never>
  startTs: number
  endTs: number
  exclusionData: ExclusionAnnotation
  exclusionIndex: number
  plot: EventPlot
  exclusionForPlotting: ExclusionAnnotation[]
}

const Exclusion = ({
  height,
  timeScale,
  startTs,
  endTs,
  exclusionData,
  exclusionIndex,
  plot,
  exclusionForPlotting,
}: ExclusionParams): JSX.Element => {
  // Used for relative mouse coord calulations
  const innerRef = useRef<SVGRectElement>(null)
  const selectedScoringCriteria = useRecoilValue(activeCriteria)
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop } =
    useTooltip()
  const { TooltipInPortal } = useTooltipInPortal()

  const handleMouseEnter = (event: React.MouseEvent<SVGElement>) => {
    showTooltip({
      tooltipLeft: event.pageX,
      tooltipTop: event.pageY,
    })
  }
  const handleExclusionRemove = useRecoilCallback(exclusionRemove)

  const handleMouseLeave = () => {
    hideTooltip()
  }

  const exclusionEventType = intToSpo2Flag(
    exclusionData.event_data?.label as number
  )
  const exclusion_label = (): string => {
    //check exclusion is not null or undefined
    if (exclusionEventType === undefined || exclusionEventType === null) {
      return "Data Excluded due to poor signal quality"
    }
    if (
      (exclusionEventType &
        (DetectionFlags.MOTION |
          DetectionFlags.LOW_SQI |
          DetectionFlags.XCORR_BELOW_90 |
          DetectionFlags.XCORR_BELOW_80)) !==
      0
    ) {
      return "Data excluded due to noise or motion artifacts"
    }
    if (
      (exclusionEventType &
        (DetectionFlags.LOW_AMP | DetectionFlags.BEAT_NOT_FOUND)) !==
      0
    ) {
      return "Data excluded due to low pulse amplitude"
    }
    return "Data Excluded due to poor signal quality"
  }

  // Convert to pixels for rendering
  const startPx = timeScale(startTs)
  const endPx = timeScale(endTs)
  const endInterval = endPx
  const widthPx = endInterval - startPx
  const range = [startPx, widthPx]
  const windowText = "Exclusion"
  const textWidthPixelRatio = 6.7
  const exclusionTypeTextWidth = windowText.length * textWidthPixelRatio
  const closeIconDefault = range[1] / 2 + exclusionTypeTextWidth / 2
  const closeIconPosition =
    widthPx < closeIconDefault ? range[1] / 2 - 7 : closeIconDefault
  const fullNightIntervalValue = useRecoilValue(fullNightInterval)
  const selectedTimeInterval = useRecoilValue(intervalTimeEventTypeAtom)
  const user = useRecoilValue(currentUser)
  const disabledGraph = useRecoilValue(disableGraph)
  return (
    <>
      <StyledExclusionGroup
        sx={{
          // Keep controls if hovering over event
          "&:hover .close-icon, &:hover .resize-icon": {
            display: "block",
          },
        }}
        left={range[0]}
        className="event"
        onMouseEnter={(event) => handleMouseEnter(event)}
        onMouseLeave={handleMouseLeave}
        onMouseMove={(event) => handleMouseEnter(event)}
      >
        <Group>
          <StyledExclusion
            fill="#33333377"
            innerRef={innerRef}
            height={height - 1}
            width={widthPx}
          />
          {widthPx > 50 && (
            <StyledMoveText
              textAnchor="middle"
              fontSize={11}
              fill="#fff"
              x={range[1] / 2}
              y={17}
              cursor="drag"
            >
              Exclusion
            </StyledMoveText>
          )}
        </Group>
        {fullNightIntervalValue !== selectedTimeInterval && !disabledGraph && (
          <Group
            onMouseDown={(e) => {
              // Prevent drawing a new event
              e.stopPropagation()
            }}
            onClick={handleExclusionRemove(
              exclusionIndex,
              plot,
              exclusionForPlotting,
              user?.uuid,
              selectedScoringCriteria
            )}
            left={closeIconPosition}
            top={5.5}
            style={{ cursor: "pointer" }}
          >
            <StyledCircle
              className="close-icon"
              sx={{ display: "none", fill: "#FFF" }}
              cx={7.5}
              cy={7.5}
              r={6}
            />
            <CancelIcon
              className="close-icon"
              sx={{ display: "none", color: "#a03434" }}
              width={15}
              height={15}
            />
          </Group>
        )}
      </StyledExclusionGroup>
      <div style={{ position: "relative" }}>
        {tooltipOpen && (
          <TooltipInPortal
            //key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            <strong>{exclusion_label()}</strong>

            <br />
          </TooltipInPortal>
        )}
        )
      </div>
    </>
  )
}

export default Exclusion
