import InternalHeader from "../../components/internalheader/InternalHeader"
import { Box, Button } from "@mui/joy"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentStudy,
  editEventExclusionStatus,
  eventExclusionLoader,
  reviewStudyChanges,
} from "../../state/study.state"
import { currentUser, isHuxleyUser } from "../../state/auth.state"
import { StudyStatus, statusStrToEnum } from "../../utils/studyUtils"
import useGenerateReportPdf from "../../hooks/useGenerateReportPdf"
import {
  isGeneratingPDFReport,
  reportStatus,
} from "../../state/pdfReport.state"
import { FEATURE_ECG_ANALYSIS } from "../../features"
import { Download } from "@mui/icons-material"
import useStudies from "../../hooks/useStudies"
import { JSONContent } from "@tiptap/core"
import { handleApiError } from "../../utils/apiUtils"
import { snackAlert } from "../../components/SnackAlerts"
import React from "react"

interface StudyScoringHeaderProps {
  studyID: string | undefined
  openInterpretationDrawer: () => void
  printSleepPdf: (
    interpretationEditorContentData: JSONContent,
    preview: boolean
  ) => Promise<void>
}

const StudyScoringHeader = React.forwardRef(
  (
    {
      studyID,
      openInterpretationDrawer,
      printSleepPdf,
    }: StudyScoringHeaderProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const study = useRecoilValue(currentStudy)
    const user = useRecoilValue(currentUser)
    const isAdmin = useRecoilValue(isHuxleyUser)
    const setReviewStudyChanges = useSetRecoilState(reviewStudyChanges)
    const setIsGeneratingPDF = useSetRecoilState(isGeneratingPDFReport)
    const [editEventExclusion, setEditEventExclusionStatus] = useRecoilState(
      editEventExclusionStatus
    )
    const setEventExclusionLoaderStatus =
      useSetRecoilState(eventExclusionLoader)
    const setSnackAlertMsg = useSetRecoilState(snackAlert)
    const [isGeneratingPDF, setIsGeneratingPdf] = useRecoilState(
      isGeneratingPDFReport
    )
    const studies = useStudies()
    const { generatePdf } = useGenerateReportPdf()
    const reportButtonStatus = useRecoilValue(reportStatus)
    const downloadSleepReport = async () => {
      try {
        await studies.downloadSleepStudy({ studyId: studyID || "" })
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
      }
    }
    const saveData = async () => {
      if (study === undefined || study.uuid === undefined) return

      setEventExclusionLoaderStatus(true)
      try {
        await studies.updateStudyEvents(study?.uuid)
        setEditEventExclusionStatus(false)
        setEventExclusionLoaderStatus(false)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
        setEditEventExclusionStatus(false)
      }
    }

    const reviewData = async () => {
      if (study === undefined || study.uuid === undefined) return

      setReviewStudyChanges(true)
      try {
        await studies.reviewStudyOrder({ studyId: study?.uuid })
        setReviewStudyChanges(false)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
        setReviewStudyChanges(false)
      }
    }

    const downloadPreviewSleepReport = async () => {
      setIsGeneratingPDF(true)
      printSleepPdf({}, true)
    }

    const printEcgPdf = async () => {
      setIsGeneratingPdf(true)
      try {
        await generatePdf({ studyId: studyID as string })
        setIsGeneratingPdf(false)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
        setIsGeneratingPdf(false)
      }
      return
    }

    const approveStudy = async () => {
      if (
        study === undefined ||
        study.studyOrder.uuid === undefined ||
        study.uuid === undefined
      )
        return

      setReviewStudyChanges(true)
      try {
        await studies.approveStudyForRelease(study.studyOrder.uuid)
        await studies.fetchStudy(studyID as string)
        setReviewStudyChanges(false)
      } catch (error) {
        handleApiError(setSnackAlertMsg)(error)
        setReviewStudyChanges(false)
      }
    }

    if (study === undefined && isGeneratingPDF === false) return null
    const headerList = [
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Patient: " + study !== undefined ? study?.patient?.name : "N/A",
        link: "/patients/" + study?.patient?.uuid,
      },
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Studies",
      },
      {
        fontWeight: 500,
        fontSize: 12,
        text: study?.id === undefined ? "N/A" : study.id.toString(),
      },
    ]
    return (
      <Box ref={ref} sx={{ display: "flex", justifyContent: "space-between" }}>
        <InternalHeader breadCrumbList={headerList}>
          <></>
        </InternalHeader>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          {study &&
            (((user?.primaryRole === "MedTech" ||
              user?.primaryRole === "OrgAdmin") &&
              statusStrToEnum[study.status] !== StudyStatus.COMPLETE &&
              statusStrToEnum[study.status] !==
                StudyStatus.READY_FOR_INTERPRETATION) ||
              (user?.primaryRole === "Physician" &&
                statusStrToEnum[study.status] !== StudyStatus.COMPLETE)) && (
              <Button
                size="md"
                disabled={!editEventExclusion}
                onClick={saveData}
              >
                Save Changes
              </Button>
            )}
          {study && statusStrToEnum[study.status] !== StudyStatus.COMPLETE && (
            <Button
              variant="solid"
              size="md"
              onClick={downloadPreviewSleepReport}
            >
              <Download sx={{ mr: 1 }} />
              Preview Report
            </Button>
          )}
          {(user?.primaryRole === "MedTech" ||
            user?.primaryRole === "OrgAdmin") &&
            study &&
            statusStrToEnum[study.status] === StudyStatus.READY_FOR_REVIEW && (
              <Button
                variant="solid"
                color="success"
                size="md"
                onClick={reviewData}
              >
                Complete Review
              </Button>
            )}
          {user?.primaryRole === "Physician" &&
            study &&
            statusStrToEnum[study.status] !== StudyStatus.COMPLETE && (
              <Button
                disabled={
                  study !== undefined
                    ? statusStrToEnum[study.status] === StudyStatus.COMPLETE
                    : false
                }
                variant="solid"
                size="md"
                onClick={openInterpretationDrawer}
                color="success"
              >
                Interpret
              </Button>
            )}
          {study && statusStrToEnum[study.status] === StudyStatus.COMPLETE && (
            <Button variant="solid" size="md" onClick={downloadSleepReport}>
              <Download sx={{ mr: 1 }} />
              Report
            </Button>
          )}
          {FEATURE_ECG_ANALYSIS && (
            <Button
              disabled={!reportButtonStatus}
              variant="solid"
              size="md"
              onClick={printEcgPdf}
            >
              ECG Report
            </Button>
          )}
          {isAdmin &&
            study &&
            statusStrToEnum[study.status] === StudyStatus.PROCESSED && (
              <Button
                variant="solid"
                size="md"
                color="success"
                onClick={approveStudy}
              >
                Approve
              </Button>
            )}
        </Box>
      </Box>
    )
  }
)

StudyScoringHeader.displayName = "StudyScoringHeader"
export default StudyScoringHeader
