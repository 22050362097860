import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import Box from "@mui/joy/Box"
import Link from "@mui/joy/Link"
import { lazy, useEffect, useState } from "react"
import useStudies from "../../hooks/useStudies"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import LoadingIndicator from "../../components/loading/LoadingIndicator"
import InternalHeader from "../../components/internalheader/InternalHeader"
import { usePortalApi } from "../../connections"
import { isLoadingStudy } from "../../state/study.state"
import { HeaderList } from "../../interfaces/header.inteface"
import { snackAlert } from "../../components/SnackAlerts"
import { currentUser } from "../../state/auth.state"
import { getQueryFromUrl } from "../../utils/urlUtils"
import { useSearchParams } from "react-router-dom"
import { GetAllStudyOrdersRequest } from "../../generated/fetchclient"

const StudyTable = lazy(() => import("../../components/study/StudyTable"))

const StudiesContent = () => {
  const api = usePortalApi()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingStudy)
  const [headerList, setHeaderList] = useState<HeaderList[]>([])
  const user = useRecoilValue(currentUser)
  const studiesApi = useStudies()
  const [, setSearchParams] = useSearchParams()
  const searchParamsQuery = getQueryFromUrl()

  const [studyQuery, setStudyQuery] =
    useState<GetAllStudyOrdersRequest>(searchParamsQuery)

  const resetSetSnackAlertMsg = useResetRecoilState(snackAlert)

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      if (user) {
        if (
          user?.primaryRole === "Physician" &&
          Object.keys(searchParamsQuery).length === 0
        ) {
          setSearchParams({
            interpretingPhysicianId: user?.uuid || "",
          })
          searchParamsQuery.interpretingPhysicianId = user?.uuid || ""
        }
        setStudyQuery(searchParamsQuery)
        await studiesApi.listStudyOrders(searchParamsQuery)
      }
      setIsLoading(false)
    }
    setHeaderList([
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Studies",
      },
    ])
    fetchData()
    return () => {
      studiesApi.resetStudyData()
      resetSetSnackAlertMsg()
    }
  }, [api, user])

  return (
    <>
      <Box
        sx={{
          px: { xs: 0, sm: 2 },
          padding: 0,
          border: 0,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InternalHeader breadCrumbList={headerList}>
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
          </InternalHeader>
        </Box>
        <StudyTable
          studyQuery={studyQuery}
          setStudyQuery={setStudyQuery}
          showAdminOptions={user?.primaryRole === "Admin"}
        ></StudyTable>
      </Box>
      {isLoading && <LoadingIndicator header="Loading Studies" content=" " />}
    </>
  )
}

export default StudiesContent
