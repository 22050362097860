import Box from "@mui/joy/Box"
import Link from "@mui/joy/Link"
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { usePortalApi } from "../../connections"
import InternalHeader from "../../components/internalheader/InternalHeader"
import LoadingIndicator from "../../components/loading/LoadingIndicator"
import { HeaderList } from "../../interfaces/header.inteface"
import usePatients from "../../hooks/usePatients"
import { useResetRecoilState } from "recoil"
import { patientCount, patients } from "../../state/patient.state"
import PatientDetail from "../../components/patient/PatientDetail"
import PatientTable from "../../components/patient/PatientTable"

const PatientsContent = () => {
  const { patientID } = useParams()
  const patientsApi = usePatients()
  const api = usePortalApi()
  const [headerList, setHeaderList] = useState<HeaderList[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const resetPatients = useResetRecoilState(patients)
  const resetPatientCount = useResetRecoilState(patientCount)

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      if (!patientID) {
        await patientsApi.listPatients()
        setHeaderList([
          {
            fontWeight: 500,
            fontSize: 12,
            text: "Patients",
          },
        ])
      }
      setIsLoading(false)
    }
    fetchData()
  }, [api, patientID])

  useEffect(() => {
    return () => {
      resetPatients()
      resetPatientCount()
    }
  }, [])

  return (
    // TODO: refactor this since it's duplicated across the table-related content files (studies, users, etc.)
    <>
      <Box
        sx={{
          px: { xs: 0, sm: 2 },
          padding: 0,
          border: 0,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InternalHeader breadCrumbList={headerList}>
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
          </InternalHeader>
        </Box>
        {patientID ? (
          <PatientDetail
            setHeaderList={setHeaderList}
            setIsLoading={setIsLoading}
          />
        ) : (
          <PatientTable />
        )}
      </Box>
      {isLoading && (
        <LoadingIndicator
          header={patientID ? "Loading Patient Data" : "Loading Patients"}
          content="Please wait"
        />
      )}
    </>
  )
}

export default PatientsContent
