import React from "react"
import { useRecoilValue } from "recoil"
import {
  activeCriteria,
  currentStudy,
  studyMetaData3,
} from "../../state/study.state"
import { sleepInfo, timeUnder88 } from "../../state/signals.state"
import {
  desatMetrics,
  desatOdiAhiMetrics,
  hrMetrics,
  spo2Metrics,
  summaryIndices3,
  summaryIndices4,
} from "../../state/graphSummary.state"
import { timeStampConverter } from "../../utils/seriesUtils"
import { formatDateTime, secondsToHms } from "../../utils/timeUtils"
import { ScoringCriteria } from "../../types/event.type"
import PdfTemplate from "./templates/PdfTemplate"
import PdfPatientInformation from "./templates/PdfPatientInformation"
import EventSummary from "./templates/EventSummary"
import SleepSummary from "./templates/SleepSummary"
import SpO2Stats from "./templates/SpO2Stats"
import DesatEventCount from "./templates/DesatEventCount"
import HeartRateStatistics from "./templates/HeartRateStatistics"
import TotalBeatsAnalyzed from "./templates/TotalBeatsAnalyzed"
import PdfClinicianSignature from "./templates/PdfClinicianSignature"
import { formatUTCDate } from "../../utils/utils"
import { OrganizationOut } from "../../generated/fetchclient"

interface PdfStudySummaryProps {
  interpretationNotesData: boolean
  logo: string
  organization?: OrganizationOut
}

const PdfStudySummary: React.FC<PdfStudySummaryProps> = ({
  interpretationNotesData,
  organization,
  logo,
}) => {
  const selectedScoringCriteria = useRecoilValue(activeCriteria)
  const studyMetaDataValue = useRecoilValue(studyMetaData3)
  const sleepInfoValue = useRecoilValue(sleepInfo)
  const spo2metrics = useRecoilValue(spo2Metrics)
  const hrmetrics = useRecoilValue(hrMetrics)
  const desatmetrics = useRecoilValue(desatMetrics)
  const study = useRecoilValue(currentStudy)
  const desatOdiAhiMetricsData = useRecoilValue(desatOdiAhiMetrics)
  const summaryindices3 = useRecoilValue(summaryIndices3)
  const summaryindices4 = useRecoilValue(summaryIndices4)
  const tu88 = useRecoilValue(timeUnder88)

  const { studyDate, studyEndDate } = {
    studyDate: new Date(timeStampConverter(studyMetaDataValue.studyStartTime)),
    studyEndDate: new Date(timeStampConverter(studyMetaDataValue.studyEndTime)),
  }

  const totalStudyTime =
    studyMetaDataValue.studyEndTime - studyMetaDataValue.studyStartTime
  const totalStudyTimeString = secondsToHms(totalStudyTime)
  const sleepEfficiency =
    (sleepInfoValue.sleepDurationSeconds / totalStudyTime) * 100
  const totalSleepString = secondsToHms(sleepInfoValue.sleepDurationSeconds)
  const effSleepTimeString = secondsToHms(sleepInfoValue.effSleepTime)
  const meanOfDesatNadirs = desatmetrics.meanDesatNadir.toFixed(1)

  const numBeats =
    selectedScoringCriteria === ScoringCriteria.THREE
      ? summaryindices3?.num_heart_beats?.toString() ?? "N/A"
      : summaryindices4?.num_heart_beats?.toString() ?? "N/A"

  const scoringCriteria =
    selectedScoringCriteria === ScoringCriteria.THREE ? "AHI-3%" : "AHI-4%"

  const pdfTemplateData = {
    studyId: study?.id,
    swVersion: studyMetaDataValue.swVersion,
  }

  const pdfPatientInformationData = {
    patientName: study?.patient?.name,
    dob: study?.patient?.dob && formatUTCDate(study.patient.dob),
    mrn: study?.patient?.mrn,
    referringPhysician: study?.patient?.referringPhysician ?? "",
    interpretingPhysician: study?.studyOrder?.interpretingPhysician.name,
    scoringCriteria,
    studyDate: formatDateTime(studyDate, "date"),
    studyStartTime: formatDateTime(studyDate, "time"),
    studyEndTime: formatDateTime(studyEndDate, "time"),
  }

  const pdfEventSummary = {
    sahi3: desatOdiAhiMetricsData.ahi3?.toFixed(1) ?? "N/A",
    sahi4: desatOdiAhiMetricsData.ahi4?.toFixed(1) ?? "N/A",
    odi3: desatOdiAhiMetricsData.odi3?.toFixed(1) ?? "N/A",
    odi4: desatOdiAhiMetricsData.odi4?.toFixed(1) ?? "N/A",
    totalRespEvents: desatmetrics.totalEvents ?? "N/A",
  }

  const pdfSleepSummary = {
    totalStudyTime: totalStudyTimeString,
    sleepTime: totalSleepString,
    effectiveSleepTime: effSleepTimeString,
    sleepLatency: secondsToHms(sleepInfoValue.sleepLatencySeconds),
    sleepEfficiency: sleepEfficiency.toFixed(0),
  }

  const spO2Statistics = {
    min: spo2metrics.minSpo2.toFixed(1),
    mean: spo2metrics.meanSpo2.toFixed(1),
    max: spo2metrics.maxSpo2.toFixed(1),
    tu88: tu88 ? (tu88 / 60).toFixed(0) : "0",
  }

  const desatEventCountData = {
    range4To9: desatmetrics.desatsBelow9,
    range10To20: desatmetrics.desatsNineTo20,
    rangeGt20: desatmetrics.desatsAbove20,
    total:
      desatmetrics.desatsBelow9 +
      desatmetrics.desatsNineTo20 +
      desatmetrics.desatsAbove20,
  }

  const heartRateStatisticsData = {
    awake: {
      min: hrmetrics.minHr.toFixed(0),
      mean: hrmetrics.meanHr.toFixed(0),
      max: hrmetrics.maxHr.toFixed(0),
    },
    sleep: {
      min: hrmetrics.minHrSleeping.toFixed(0),
      mean: hrmetrics.meanHrSleeping.toFixed(0),
      max: hrmetrics.maxHrSleeping.toFixed(0),
    },
  }

  return (
    <PdfTemplate
      study_id={pdfTemplateData.studyId}
      organization={organization}
      sw_version={pdfTemplateData.swVersion}
      logo={logo}
    >
      <div className="section-header">Study Information</div>
      <PdfPatientInformation
        patient_name={pdfPatientInformationData.patientName}
        dob={pdfPatientInformationData.dob}
        mrn={pdfPatientInformationData.mrn}
        referring_physician={pdfPatientInformationData.referringPhysician}
        interpreting_physician={pdfPatientInformationData.interpretingPhysician}
        scoring_criteria={pdfPatientInformationData.scoringCriteria}
        study_date={pdfPatientInformationData.studyDate}
        study_start_time={pdfPatientInformationData.studyStartTime}
        study_end_time={pdfPatientInformationData.studyEndTime}
      />
      <div className="section-header">Summary Data</div>
      <div id="summary">
        <div className="summary-column">
          <EventSummary
            sahi_3={pdfEventSummary.sahi3}
            sahi_4={pdfEventSummary.sahi4}
            odi_3={pdfEventSummary.odi3}
            odi_4={pdfEventSummary.odi4}
            total_resp_events={pdfEventSummary.totalRespEvents}
          />
        </div>
        <div className="summary-column">
          <SleepSummary
            total_study_time={pdfSleepSummary.totalStudyTime}
            sleep_time={pdfSleepSummary.sleepTime}
            effective_sleep_time={pdfSleepSummary.effectiveSleepTime}
            sleep_latency={pdfSleepSummary.sleepLatency}
            sleep_efficiency={pdfSleepSummary.sleepEfficiency}
          />
        </div>
        <div className="summary-column">
          <SpO2Stats
            min={spO2Statistics.min}
            mean={spO2Statistics.mean}
            max={spO2Statistics.max}
            tu88={spO2Statistics.tu88}
          />
          <DesatEventCount
            range_4_9={desatEventCountData.range4To9}
            range_10_20={desatEventCountData.range10To20}
            range_gt_20={desatEventCountData.rangeGt20}
            total={desatEventCountData.total}
            meanOfDesatNadirs={meanOfDesatNadirs}
            selectedScoringCriteria={selectedScoringCriteria}
          />
        </div>
        <div className="summary-column">
          <HeartRateStatistics
            awakemin={heartRateStatisticsData.awake.min}
            awakemean={heartRateStatisticsData.awake.mean}
            awakemax={heartRateStatisticsData.awake.max}
            sleepmin={heartRateStatisticsData.sleep.min}
            sleepmean={heartRateStatisticsData.sleep.mean}
            sleepmax={heartRateStatisticsData.sleep.max}
          />
          <TotalBeatsAnalyzed total_beats_analyzed={numBeats} />
        </div>
      </div>
      <div className="graph-placeholder"></div>
      {interpretationNotesData ? (
        <PdfClinicianSignature />
      ) : (
        <div className="empty-block"></div>
      )}
    </PdfTemplate>
  )
}

export default PdfStudySummary
