import { HelpOutline } from "@mui/icons-material"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Tooltip from "@mui/joy/Tooltip"

interface DataDisplayProps {
  label: string
  value: React.ReactNode
  tooltipHelp?: string
}

const AccordianDataDisplay: React.FC<DataDisplayProps> = ({
  label,
  value,
  tooltipHelp,
}) => (
  <Typography level="title-sm">
    <Stack
      direction={{ sm: "column", lg: "row" }}
      spacing={0.5}
      alignItems={"center"}
    >
      <Typography>{label}:</Typography>
      <Typography fontWeight={700}>{value}</Typography>
      {tooltipHelp && (
        <Tooltip title={tooltipHelp} enterTouchDelay={0}>
          <HelpOutline />
        </Tooltip>
      )}
    </Stack>
  </Typography>
)

export default AccordianDataDisplay
