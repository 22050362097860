import React from "react"
import AccordianDataDisplay from "../../accordian/AccordianDataDisplay"
import CustomDivider from "../../divider/CustomDivider"

interface DataDisplayItem {
  label: string
  value: string | number
}

interface ScoringDataRowProps {
  item: DataDisplayItem
  showDivider: boolean
}

const ScoringDataRow: React.FC<ScoringDataRowProps> = ({
  item,
  showDivider,
}) => {
  return (
    <>
      <AccordianDataDisplay label={item.label} value={item.value} />
      {showDivider && <CustomDivider orientation="vertical" />}
    </>
  )
}

export default ScoringDataRow
