import React from "react"
import { useRecoilValue } from "recoil"
import IconButton from "@mui/joy/IconButton"
import AccordionSummary from "@mui/joy/AccordionSummary"
import AccordionDetails from "@mui/joy/AccordionDetails"
import AccordionGroup from "@mui/joy/AccordionGroup"
import Accordion from "@mui/joy/Accordion"
import Stack from "@mui/joy/Stack"
import TuneIcon from "@mui/icons-material/Tune"
import { ScoringHeaderProps } from "../../../interfaces/header.inteface"
import {
  desatMetrics,
  desatOdiAhiMetrics,
  ecgMetrics,
  hrMetrics,
  spo2Metrics,
  //summaryIndices,
} from "../../../state/graphSummary.state"
import { activeCriteria, studyMetaData3 } from "../../../state/study.state"
import { sleepInfo, timeUnder88 } from "../../../state/signals.state"
import { secondsToHms } from "../../../utils/timeUtils"
import { ScoringCriteria } from "../../../types/event.type"
import { FEATURE_ECG_ANALYSIS } from "../../../features"
import SelectionScoringCriteria from "../selectionScoringCriteria/SelectionScoringCriteria"
import CustomDivider from "../../divider/CustomDivider"
import ScoringDataRow from "./ScoringDataRow"

const ScoringHeader: React.FC<ScoringHeaderProps> = ({
  children,
  onOpenPlotOrderModal,
}) => {
  const desatmetrics = useRecoilValue(desatMetrics)
  const desatOdiAhiMetricsData = useRecoilValue(desatOdiAhiMetrics)
  const studymetrics = useRecoilValue(studyMetaData3)
  const spo2metrics = useRecoilValue(spo2Metrics)
  const tu88 = useRecoilValue(timeUnder88)
  const hrmetrics = useRecoilValue(hrMetrics)
  const sleepinfo = useRecoilValue(sleepInfo)
  const selectedScoringCriteria = useRecoilValue(activeCriteria)
  //const summaryindices = useRecoilValue(summaryIndices) //only the numb beats should be used
  const ecgMetricsData = useRecoilValue(ecgMetrics)
  const totalDesats =
    desatmetrics.desatsAbove20 +
    desatmetrics.desatsNineTo20 +
    desatmetrics.desatsBelow9
  const seriesSummary = [
    {
      label: "Sleep Time",
      value: secondsToHms(sleepinfo.sleepDurationSeconds),
    },
    {
      label: "Eff. Sleep Time",
      value: secondsToHms(sleepinfo.effSleepTime),
      tooltipHelp:
        "Effective Sleep Time is the time in sleep which has no data exlcusions",
    },
    {
      label: "Sleep Efficiency",
      value: `${sleepinfo.sleepEfficiencyPercent.toFixed(0)}%`,
    },
    {
      label: "sAHI 3",
      value: desatOdiAhiMetricsData.ahi3?.toFixed(1) ?? "N/A",
    },
    {
      label: "sAHI 4",
      value: desatOdiAhiMetricsData.ahi4?.toFixed(1) ?? "N/A",
    },
    {
      label: "ODI 3",
      value: desatOdiAhiMetricsData.odi3?.toFixed(1) ?? "N/A",
    },
    {
      label: "ODI 4",
      value: desatOdiAhiMetricsData.odi4?.toFixed(1) ?? "N/A",
    },
  ]
  const spO2Details = [
    {
      label: "Max SpO2",
      value: spo2metrics.maxSpo2?.toFixed(1) ?? "N/A",
    },
    {
      label: "Min SpO2",
      value: spo2metrics.minSpo2?.toFixed(1) ?? "N/A",
    },
    {
      label: "Mean SpO2",
      value: spo2metrics.meanSpo2?.toFixed(1) ?? "N/A",
    },
    {
      label: "≤88%",
      value: tu88 ? (tu88 / 60).toFixed(0) + "min" : "0",
    },
  ]
  const desatMetricsDisplayItems = [
    {
      label: "Avg. Desat. Nadir",
      value: desatmetrics.meanDesatNadir?.toFixed(1) ?? "N/A",
    },
    {
      label:
        "Desats " +
        (selectedScoringCriteria === ScoringCriteria.THREE ? "3" : "4") +
        "-9%",
      value: desatmetrics.desatsBelow9 ?? "N/A",
    },
    {
      label: "Desats 10-20%",
      value: desatmetrics.desatsNineTo20 ?? "N/A",
    },
    {
      label: "Num Desats >20%",
      value: desatmetrics.desatsAbove20 ?? "N/A",
    },
    {
      label: "Tot. Desats",
      value: totalDesats ?? "N/A",
    },
  ]
  const hrDetails = [
    {
      label: "Mean HR",
      value: hrmetrics.meanHr?.toFixed(0) ?? "N/A",
    },
    {
      label: "Max HR",
      value: hrmetrics.maxHr?.toFixed(0) ?? "N/A",
    },
    {
      label: "Min HR",
      value: hrmetrics.minHr?.toFixed(0) ?? "N/A",
    },
    {
      label: "Mean HR Sleep",
      value: hrmetrics.meanHrSleeping?.toFixed(0) ?? "N/A",
    },
    {
      label: "Max HR Sleep",
      value: hrmetrics.maxHrSleeping?.toFixed(0) ?? "N/A",
    },
    {
      label: "Min HR Sleep",
      value: hrmetrics.minHrSleeping?.toFixed(0) ?? "N/A",
    },
  ]
  const ectopicDetails = [
    {
      label: "Total VEB",
      value: ecgMetricsData?.vebEvents ?? "N/A",
    },
    {
      label: "Total SVEB",
      value: ecgMetricsData?.svebEvents ?? "N/A",
    },
  ]

  return (
    <>
      <AccordionGroup color="neutral" variant="plain">
        <Accordion
          sx={{
            paddingLeft: 0,
          }}
        >
          <AccordionSummary sx={{ paddingY: 0.5 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1.5, md: 2 }}
                sx={{ flexGrow: 1 }}
              >
                <ScoringDataRow
                  item={{
                    label: "Study Start",
                    value: new Date(
                      studymetrics.studyStartTime * 1000
                    ).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    }),
                  }}
                  showDivider={seriesSummary.length > 0}
                />
                {seriesSummary.map((item, index) => (
                  <ScoringDataRow
                    key={index}
                    item={item}
                    showDivider={index < seriesSummary.length - 1}
                  />
                ))}
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <SelectionScoringCriteria />
                <IconButton onClick={onOpenPlotOrderModal}>
                  <TuneIcon />
                </IconButton>
              </Stack>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack direction="column" spacing={{ sm: 1, md: 1, xl: 1 }}>
              {(hrDetails.length > 0 ||
                (FEATURE_ECG_ANALYSIS && ectopicDetails.length > 0)) && (
                <>
                  <CustomDivider sx={{ my: 2 }} />
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 1.5, md: 2 }}
                    alignItems="center"
                    sx={{ paddingY: 0.5 }}
                  >
                    {hrDetails.map((item, index) => (
                      <ScoringDataRow
                        key={`hr-${index}`}
                        item={item}
                        showDivider={
                          index < hrDetails.length - 1 || FEATURE_ECG_ANALYSIS
                        }
                      />
                    ))}

                    {FEATURE_ECG_ANALYSIS &&
                      ectopicDetails.map((item, index) => (
                        <ScoringDataRow
                          key={`ectopic-${index}`}
                          item={item}
                          showDivider={index < ectopicDetails.length - 1}
                        />
                      ))}
                  </Stack>
                </>
              )}

              {(spO2Details.length > 0 ||
                desatMetricsDisplayItems.length > 0) && (
                <>
                  <CustomDivider sx={{ my: 2 }} />
                  <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 1.5, md: 2 }}
                    alignItems="center"
                    sx={{ paddingY: 0.5 }}
                  >
                    {spO2Details.map((item, index) => (
                      <ScoringDataRow
                        key={`spO2-${index}`}
                        item={item}
                        showDivider={
                          index < spO2Details.length - 1 ||
                          desatMetricsDisplayItems.length > 0
                        }
                      />
                    ))}

                    {desatMetricsDisplayItems.map((item, index) => (
                      <ScoringDataRow
                        key={`desat-${index}`}
                        item={item}
                        showDivider={
                          index < desatMetricsDisplayItems.length - 1
                        }
                      />
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
      {children}
    </>
  )
}

export default ScoringHeader
