import { atom, selectorFamily } from "recoil"
import { defaultPlotNumberIntervalStatusValues } from "../const/signals.const"
import { PlotNumberStatus } from "../interfaces/signals.interface"

export const chartWidth = atom({
  key: "chartWidth",
  default: 0,
})

export const mainHeight = atom({
  key: "mainHeight",
  default: 0,
})

export const disableGraph = atom<boolean>({
  key: "disableGraph",
  default: false,
})

export const plotNumberStatus = atom<PlotNumberStatus>({
  key: "plotNumberIntervalStatus",
  default: { ...defaultPlotNumberIntervalStatusValues },
})

export const plotNumberStatusValues = selectorFamily({
  key: "plotNumberIntervalValues",
  get:
    (signal: keyof PlotNumberStatus) =>
    ({ get }) => {
      const state = get(plotNumberStatus)
      return state[signal]
    },
  set:
    (signal: keyof PlotNumberStatus) =>
    ({ set }, newValue) => {
      set(plotNumberStatus, (prevState) => ({
        ...prevState,
        [signal]: newValue,
      }))
    },
})
