import { Button, Divider, Grid, Input, Option, Select } from "@mui/joy"
import * as EmailValidator from "email-validator"
import phone from "phone"
import React, { useEffect, useState } from "react"
import PhoneInput, { Value } from "react-phone-number-input/input"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { UserIn } from "../../../generated/fetchclient"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import { nilUuid } from "../../../constants"
import { userRoleOptions } from "../../../utils/userUtils"
import { currentUser, isHuxleyUser } from "../../../state/auth.state"
import useUsers from "../../../hooks/useUsers"
import useOrganizations from "../../../hooks/useOrganizations"
import { ValueOf } from "../../../types/event.type"
import GridFormInput from "../../forms/grid/GridFormInput"

type NewUserFormParams = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const emptyUserInput = {
  cognitoId: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  organizationId: nilUuid,
  groups: [],
  primaryRoleName: "",
}

const emptyInputErrors = {
  email: "",
  phone: "",
  organization: "",
}

const userRoleOptionsNonHuxley = userRoleOptions.filter(
  (option) => !option.value.includes("Huxley")
)

function NewUserForm({ setOpen }: NewUserFormParams) {
  const api = usePortalApi()
  const usersApi = useUsers()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const isAdmin = useRecoilValue(isHuxleyUser)
  const user = useRecoilValue(currentUser)
  const [userInput, setUserInput] = useState<UserIn>(emptyUserInput)
  const [inputErrors, setInputErrors] = useState(emptyInputErrors)
  const { organizations, listOrganizations } = useOrganizations()

  const handlePropChange = (property: keyof UserIn, value: ValueOf<UserIn>) => {
    setUserInput({
      ...userInput,
      [property]: value,
    })
  }

  const validateForm = () => {
    let emailError = ""
    let phoneError = ""
    let organizationError = ""

    if (!phone(userInput.phone).isValid) {
      phoneError = "Invalid phone number"
    }

    if (!EmailValidator.validate(userInput.email)) {
      emailError = "Invalid email address"
    }

    if (
      !userInput.primaryRoleName.includes("Huxley") &&
      !userInput.organizationId
    ) {
      organizationError = "A non-Huxley user must be part of an organization"
    }

    const errors = {
      email: emailError,
      phone: phoneError,
      organization: organizationError,
    }

    setInputErrors(errors)

    return Object.values(errors).every((e) => e === "")
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (!validateForm()) {
      return
    }

    await usersApi.createUser(userInput)

    setSnackAlertMsg({
      open: true,
      message: "User created successfully",
      severity: "success",
      autoHideDuration: 5000,
    })

    setOpen(false)
  }

  useEffect(() => {
    if (!user) return

    const loadOrganizations = async () => {
      await listOrganizations()
    }

    isAdmin
      ? loadOrganizations()
      : setUserInput({
          ...userInput,
          organizationId: user.organization,
        })
  }, [user, isAdmin])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <GridFormInput width={6} label="First Name">
          <Input
            value={userInput.firstName}
            onChange={(e) => handlePropChange("firstName", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={6} label="Last Name">
          <Input
            value={userInput.lastName}
            onChange={(e) => handlePropChange("lastName", e.target.value)}
          />
        </GridFormInput>
        <GridFormInput
          width={12}
          label="Email Address"
          error={inputErrors.email}
        >
          <Input
            type="email"
            error={!!inputErrors.email}
            value={userInput.email}
            onChange={(e) => handlePropChange("email", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput
          width={12}
          label="Phone Number"
          error={inputErrors.phone}
        >
          <PhoneInput
            className={"phone-input " + (inputErrors.phone ? "error" : "")}
            country="US"
            value={userInput.phone as Value}
            onChange={(value) => handlePropChange("phone", value || "")}
            required
          />
        </GridFormInput>
        {isAdmin && (
          <GridFormInput
            width={6}
            label="Organization"
            error={inputErrors.organization}
          >
            <Select
              value={userInput.organizationId}
              onChange={(_, value) => {
                if (value !== null) {
                  handlePropChange("organizationId", value)
                }
              }}
              placeholder="Select one"
            >
              {userInput.primaryRoleName.includes("Huxley") ? (
                <Option key={0} value={nilUuid}>
                  None
                </Option>
              ) : (
                organizations.map((option, index) => {
                  return (
                    <Option key={index + 1} value={option.uuid}>
                      {option.name}
                    </Option>
                  )
                })
              )}
            </Select>
          </GridFormInput>
        )}
        <GridFormInput width={6} label="Role">
          <Select
            value={userInput.primaryRoleName}
            onChange={(_, value) => {
              if (value) {
                setUserInput({
                  ...userInput,
                  organizationId: value.includes("Huxley")
                    ? nilUuid
                    : userInput.organizationId,
                  groups: [value],
                  primaryRoleName: value,
                })
              }
            }}
            placeholder="Select one"
            required
          >
            {(isAdmin ? userRoleOptions : userRoleOptionsNonHuxley).map(
              (option, index) => {
                return (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                )
              }
            )}
          </Select>
        </GridFormInput>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <Button type="submit">Create</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewUserForm
