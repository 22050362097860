import { getAlignedTicksInterval } from "@huxley-medical/react-components/utils"
import { useTheme } from "@mui/joy"
import { Axis, Orientation, TickFormatter } from "@visx/axis"
import { NumberValue, ScaleLinear } from "d3-scale"
import CustomTick from "./CustomTick"

interface AxisGraphProps {
  top: number
  left: number
  hideTicks: boolean
  axisClassName?: string
  tickTransform: string
  scale: ScaleLinear<number, number, never>
  orientation: string | undefined
  minTick: number
  maxTick: number
  tickInterval: number
  tickFormat: TickFormatter<NumberValue>
}

type OrientationType = (typeof Orientation)[keyof typeof Orientation]

const AxisGraph = ({
  top,
  hideTicks,
  left,
  axisClassName,
  tickTransform,
  orientation,
  scale,
  minTick,
  maxTick,
  tickInterval,
  tickFormat,
}: AxisGraphProps) => {
  const tickValues = getAlignedTicksInterval({
    minTick,
    maxTick,
    tickInterval: tickInterval,
  })

  tickValues.pop()
  tickValues.push(maxTick)

  const lastTickX = scale(tickValues[tickValues.length - 1])
  const theme = useTheme()
  const isSmallScreen = window.innerWidth <= theme.breakpoints.values.sm

  return (
    <Axis
      top={top}
      left={left}
      hideTicks={hideTicks}
      // Shift tick numbers to the left
      tickTransform={tickTransform}
      axisClassName={axisClassName}
      // Don't hide entire axis, this fixes a bottom plot line
      // zindex as side-effect
      tickValues={tickValues}
      tickFormat={tickFormat}
      orientation={orientation ? (orientation as OrientationType) : undefined}
      scale={scale}
      tickComponent={({ x, y, formattedValue }) => (
        <CustomTick
          x={x}
          y={y}
          formattedValue={formattedValue}
          isLastTick={x === lastTickX} // Flag the last tick
          isSmallScreen={isSmallScreen}
        />
      )}
    />
  )
}

export default AxisGraph
