import Divider from "@mui/joy/Divider"
import { CustomDividerProps } from "../../interfaces"

const CustomDivider: React.FC<CustomDividerProps> = ({
  orientation = "horizontal",
  sx = {},
  backgroundColor = "#D9D9D9",
  ...props
}) => {
  return (
    <Divider
      orientation={orientation}
      sx={{
        background: backgroundColor,
        ...sx,
      }}
      {...props}
    />
  )
}

export default CustomDivider
