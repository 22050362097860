import { lazy, Suspense, useEffect, useState } from "react"
import Box from "@mui/joy/Box"
import Dropdown from "@mui/joy/Dropdown"
import FormControl from "@mui/joy/FormControl"
import IconButton from "@mui/joy/IconButton"
import Input from "@mui/joy/Input"
import Menu from "@mui/joy/Menu"
import MenuButton from "@mui/joy/MenuButton"
import MenuItem from "@mui/joy/MenuItem"
import Stack from "@mui/joy/Stack"
import MoreVert from "@mui/icons-material/MoreVert"
import Refresh from "@mui/icons-material/Refresh"
import Search from "@mui/icons-material/Search"
import AddIcon from "@mui/icons-material/Add"
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid"
import { useSetRecoilState } from "recoil"
import { deleteDeviceModalOpen, deviceToBeDeleted } from "./state"
import { GetAllDevicesRequest } from "../../generated/fetchclient"
import { StyledDataGrid } from "../../styled/DataGrid"
import { createBasicGridColDef } from "../../utils/tableUtils"
import { removeEmptyValuesFromObject } from "../../utils/utils"
import useDevices from "../../hooks/useDevices"
import DeleteDeviceModal from "./DeleteDeviceModal"
import EditDeviceModal from "./EditDeviceModal"
import Button from "@mui/joy/Button"

interface DeviceTableRow {
  id?: string
  serialNumber: string
  deviceType?: string
}

const emptyDeviceQuery: GetAllDevicesRequest = {
  serialNumber: "",
}

const NewDeviceModal = lazy(() => import("./newdevicemodal"))

function DeviceTable() {
  const devices = useDevices()
  const { devices: apiDevices, deviceCount } = devices
  const [isLoading, setIsLoading] = useState(false)
  const setDeleteModalOpenState = useSetRecoilState(deleteDeviceModalOpen)
  const setDeviceToBeDeleted = useSetRecoilState(deviceToBeDeleted)
  const [tableRows, setTableRows] = useState<DeviceTableRow[]>(
    [] as DeviceTableRow[]
  )
  const [deviceQuery, setDeviceQuery] =
    useState<GetAllDevicesRequest>(emptyDeviceQuery)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>()
  const [openNewModalStatus, setOpenNewModalStatus] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editModalData, setEditModalData] = useState({
    uuid: "",
    serialNumber: "",
    deviceType: "sansa",
  })

  useEffect(() => {
    const rows: DeviceTableRow[] = apiDevices
      .map((d) => {
        return {
          id: d.uuid,
          serialNumber: d.serialNumber,
          deviceType: d.deviceType,
        }
      })
      .sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))

    setTableRows(rows)
  }, [apiDevices])

  const openDeleteModal = (serialNumber: string) => {
    setDeviceToBeDeleted(serialNumber)
    setDeleteModalOpenState(true)
  }

  const openEditModal = (row: DeviceTableRow) => {
    setEditModalData({
      uuid: row.id ?? "",
      serialNumber: row.serialNumber,
      deviceType: row.deviceType ?? "sansa",
    })
    setEditModalOpen(true)
  }

  const searchDevices = async (
    paginationModel: GridPaginationModel | undefined
  ) => {
    const filteredQuery = removeEmptyValuesFromObject(deviceQuery)

    setIsLoading(true)
    if (paginationModel === undefined) {
      await devices.listDevices(filteredQuery)
    } else {
      await devices.listDevices({
        ...filteredQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      })
    }
    setIsLoading(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchDevices(paginationModel)
    }
  }

  const columns: GridColDef[] = [
    createBasicGridColDef("id", "ID", 0),
    createBasicGridColDef("serialNumber", "Serial Number"),
    {
      ...createBasicGridColDef("deviceType", "Type"),
      renderCell: (params) => {
        return <div>{params.value?.toString().toUpperCase()}</div>
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        const currentRow = params.row
        const editDevice = () => {
          openEditModal(currentRow)
        }

        const deleteDevice = () => {
          openDeleteModal(currentRow.serialNumber)
        }
        return (
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{
                root: { variant: "outlined", color: "neutral" },
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVert />
            </MenuButton>
            <Menu>
              <MenuItem onClick={editDevice}>Edit</MenuItem>
              <MenuItem color="danger" onClick={deleteDevice}>
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        )
      },
    },
  ]

  return (
    <>
      <EditDeviceModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        deviceData={editModalData}
      />
      <DeleteDeviceModal />
      <Stack spacing={2} sx={{ overflow: "hidden" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack direction="row" spacing={2} display="flex" alignItems="center">
            <FormControl>
              <Input
                placeholder="Serial Number"
                value={deviceQuery.serialNumber}
                onChange={(e) =>
                  setDeviceQuery({ serialNumber: e.target.value })
                }
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <IconButton
              onClick={() => searchDevices(paginationModel)}
              variant="solid"
              color="primary"
            >
              <Search />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeviceQuery(emptyDeviceQuery)
                if (paginationModel === undefined) {
                  devices.listDevices()
                } else {
                  devices.listDevices({
                    limit: paginationModel.pageSize,
                    offset: paginationModel.page * paginationModel.pageSize,
                  })
                }
              }}
              variant="outlined"
              color="primary"
            >
              <Refresh />
            </IconButton>
          </Stack>
          <Button
            size="sm"
            startDecorator={<AddIcon />}
            onClick={() => setOpenNewModalStatus(true)}
          >
            New Device
          </Button>
        </Box>
        <Box sx={{ height: "calc(100% - 52px)" }}>
          <StyledDataGrid
            rows={tableRows}
            columns={columns}
            rowCount={deviceCount}
            loading={isLoading}
            paginationMode="server"
            onPaginationModelChange={(paginationModel) => {
              setPaginationModel(paginationModel)
              searchDevices(paginationModel)
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
        <Suspense fallback={null}>
          {openNewModalStatus && (
            <NewDeviceModal
              openNewModalStatus={openNewModalStatus}
              setOpenNewModalStatus={setOpenNewModalStatus}
            />
          )}
        </Suspense>
      </Stack>
    </>
  )
}

export default DeviceTable
