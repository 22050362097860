import { DialogTitle, Modal, ModalClose, ModalDialog, Stack } from "@mui/joy"
import NewDeviceForm from "./newdevicemodal/NewDeviceForm"
import { DeviceOut } from "../../generated/fetchclient"
import { Dispatch, SetStateAction } from "react"

interface EditModalProps {
  deviceData: DeviceOut
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

function EditDeviceModal({ deviceData, open, setOpen }: EditModalProps) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog sx={{ maxWidth: 880, overflowY: "auto" }}>
        <ModalClose />
        <Stack spacing={2}>
          <DialogTitle>Edit Device:</DialogTitle>
          <NewDeviceForm deviceData={deviceData} setOpen={setOpen} />
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

export default EditDeviceModal
