import * as React from "react"
import Refresh from "@mui/icons-material/Refresh"
import Search from "@mui/icons-material/Search"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Chip from "@mui/joy/Chip"
import Dropdown from "@mui/joy/Dropdown"
import Option from "@mui/joy/Option"
import FormControl from "@mui/joy/FormControl"
import IconButton from "@mui/joy/IconButton"
import Input from "@mui/joy/Input"
import Menu from "@mui/joy/Menu"
import MenuButton from "@mui/joy/MenuButton"
import MenuItem from "@mui/joy/MenuItem"
import Select from "@mui/joy/Select"
import Sheet from "@mui/joy/Sheet"
import Stack from "@mui/joy/Stack"
import Switch from "@mui/joy/Switch"
import ListDivider from "@mui/joy/ListDivider"
import Typography from "@mui/joy/Typography"
import AddIcon from "@mui/icons-material/Add"
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid"
import { lazy, Suspense, useEffect, useRef, useState } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { formatDate } from "../../utils/formatDate"
import { createBasicGridColDef } from "../../utils/tableUtils"
import { removeEmptyValuesFromObject } from "../../utils/utils"
import { getQueryFromUrl } from "../../utils/urlUtils"
import {
  SimplifiedStudyStatus,
  StatusTextToStr,
  StudyStatus,
  getStudyStatusColorAndText,
  simplifyStudyStatus,
  statusStrToEnum,
  studyStatusValues,
} from "../../utils/studyUtils"
import MoreVert from "@mui/icons-material/MoreVert"
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined"
import useStudies from "../../hooks/useStudies"
import { emptySelectedPatient } from "./newstudymodal/NewStudyModal"
import { emptyStudyInput } from "./newstudymodal/StudyDetailForm"
import { currentUser, isHuxleyUser } from "../../state/auth.state"
import { PatientIdentifier } from "../../types/patient.type"
import { StyledDataGrid } from "../../styled/DataGrid"
import { studyToBeUploaded } from "../../state/modal.state"
import { GetAllStudyOrdersRequest, UserOut } from "../../generated/fetchclient"
import useOrganizations from "../../hooks/useOrganizations"

const EditStudyModal = lazy(() => import("./EditStudyModal"))
const DeleteStudyModal = lazy(() => import("./DeleteStudyModal"))
const FulfillStudyModal = lazy(() => import("./FulfillStudyModal"))
const StudyUploadModal = lazy(() => import("./StudyUploadModal"))
const NewStudyModal = lazy(() => import("./newstudymodal/NewStudyModal"))
const NotesStudyModal = lazy(() => import("./NotesStudyModal"))

interface StudyTableProps {
  showAdminOptions?: boolean
  studyQuery: GetAllStudyOrdersRequest
  setStudyQuery: React.Dispatch<React.SetStateAction<GetAllStudyOrdersRequest>>
}
export interface StudyTableRow {
  id?: string
  readableId: number
  name: string
  mrn: string
  dob: Date
  referringPhysician: string
  orderingPhysician: string
  date: Date
  interpretingPhysician: string
  scoringCriteria: string
  // sleepTime: string  TODO: implement later from JSON events
  device: string
  status: StudyStatus
  patientId: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  scheduledDate: Date
  oxygenDesatThreshold: string
  orderingPhysicianId: string
  interpretingPhysicianId: string
  assignedDevice: string
  notes: string
  type: string
  modalStatus: string
}

const oxygenDesatThresholdToScoringCriteria: { [key: string]: string } = {
  three: "3%",
  four: "4%",
  both: "3% and 4%",
}

export function StudyStatusIndicator(status: StudyStatus) {
  const repr = getStudyStatusColorAndText(status)
  return (
    <Chip
      color={repr.color}
      sx={{
        "--Chip-radius": "4px",
      }}
      variant="outlined"
    >
      {repr.text}
    </Chip>
  )
}

const emptyStudyQuery = (user?: UserOut): GetAllStudyOrdersRequest => {
  return {
    patientName: "",
    mrn: "",
    studyStatuses: [],
    referringPhysicianName: "",
    interpretingPhysicianName: "",
    interpretingPhysicianId:
      user?.primaryRole === "Physician" ? user?.uuid : "",
  }
}

const canAnalyzeStudyStatuses = [
  StudyStatus.COMPLETE,
  StudyStatus.READY_FOR_INTERPRETATION,
  StudyStatus.READY_FOR_REVIEW,
]

function StudyTable(props: StudyTableProps) {
  const DEBOUNCE_DELAY = 500

  const { organizations, listOrganizations } = useOrganizations()
  const isAdmin = useRecoilValue(isHuxleyUser)

  const [tableRows, setTableRows] = useState<StudyTableRow[]>(
    [] as StudyTableRow[]
  )
  const studies = useStudies()
  const { studies: apiStudies, studyCount } = studies
  const [isLoading, setIsLoading] = useState(false)
  const user = useRecoilValue(currentUser)
  const isUserPhysician = user?.primaryRole === "Physician"

  const [deleteStudyModalStatus, setDeleteStudyModalStatus] =
    useState<boolean>(false)
  const [fullFillStudyModalStatus, setFullFillStudyModalStatus] =
    useState<boolean>(false)
  const [newStudyModalOpenStatus, setNewStudyModalOpenStatus] =
    useState<boolean>(false)
  const [notesStudyModalStatus, setNotesStudyModalStatus] = useState<string>("")
  const [studyToBeDeleted, setStudyToBeDeleted] = useState<string>("")
  const [studyToBeFulfilled, setStudyToBeFulfilled] = useState<string>("")
  const [studyOrder, setStudyToBeUploaded] = useRecoilState(studyToBeUploaded)
  const nav = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const searchParamsQuery: GetAllStudyOrdersRequest = getQueryFromUrl()

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [statusFilter, setStatusFilter] = useState<string[]>(
    searchParamsQuery["studyStatuses"]?.map(
      (status: string) => statusStrToEnum[status]
    ) ?? []
  )
  const [selfInterpretingPhysician, setSelfInterpretingPhysician] = useState(
    user || Object.keys(searchParamsQuery).length > 0
      ? isUserPhysician ||
          Object.keys(searchParamsQuery).includes("interpretingPhysicianId")
      : true
  )
  const [selectedPatient, setSelectedPatient] =
    useState<PatientIdentifier>(emptySelectedPatient)
  const [editModalData, setEditModalData] = useState(emptyStudyInput)
  const studyStatusValue = React.useMemo(() => studyStatusValues(), [])

  useEffect(() => {
    const rows = apiStudies.map((s) => {
      const status = statusStrToEnum[s.status]
      return {
        id: s.uuid,
        readableId: s.id,
        name: s.patient.name,
        mrn: s.patient.mrn,
        dob: s.patient.dob,
        referringPhysician: s.patient.referringPhysician,
        orderingPhysician: s.orderingPhysician.name,
        date: s.scheduledDate,
        interpretingPhysician: s.interpretingPhysician.name,
        scoringCriteria:
          oxygenDesatThresholdToScoringCriteria[s.oxygenDesatThreshold],
        device: `SANSA ${s.type}`,
        status: props.showAdminOptions ? status : simplifyStudyStatus(status),
        activeStudyId: s.activeStudyId ?? "",
        organization: s.organization,
        patientId: s.patient.uuid ?? "",
        address1: s.address1,
        address2: s.address2 ?? "",
        city: s.city,
        state: s.state,
        zip: s.zip,
        scheduledDate: s.scheduledDate,
        oxygenDesatThreshold: s.oxygenDesatThreshold,
        orderingPhysicianId: s.orderingPhysician.uuid,
        interpretingPhysicianId: s.interpretingPhysician.uuid,
        assignedDevice: s.assignedDevice,
        notes: s.notes ?? "",
        type: s.type,
        modalStatus: s.status,
      }
    })

    setTableRows(rows)
  }, [apiStudies])

  useEffect(() => {
    if (isAdmin) listOrganizations()
  }, [isAdmin])

  // Rerender when selfInterpretingPhysician changes
  useEffect(() => {
    if (user) {
      props.setStudyQuery({
        ...props.studyQuery,
        interpretingPhysicianId: selfInterpretingPhysician ? user?.uuid : "",
        interpretingPhysicianName: selfInterpretingPhysician
          ? `${user?.firstName} ${user?.lastName}`
          : "",
      })
    }
    return
  }, [selfInterpretingPhysician])

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current)
    }
    const queryParams = {
      patientName: props.studyQuery.patientName ?? "",
      mrn: props.studyQuery.mrn ?? "",
      interpretingPhysicianName:
        props.studyQuery.interpretingPhysicianName ?? "",
      referringPhysicianName: props.studyQuery.referringPhysicianName ?? "",
      studyStatuses: props.studyQuery.studyStatuses ?? "",
      interpretingPhysicianId: props.studyQuery.interpretingPhysicianId ?? "",
      organizationId: props.studyQuery.organizationId ?? "",
      orderingPhysicianId: props.studyQuery.orderingPhysicianId ?? "",
    }
    // remove keys from queryParams that are empty strings
    Object.keys(queryParams).forEach(
      // @ts-ignore
      (key) => queryParams[key] === "" && delete queryParams[key]
    )
    setSearchParams(queryParams)
    debounceTimeoutRef.current = setTimeout(() => {
      // Fetch studies when the delay has passed
      if (props.studyQuery) {
        setIsLoading(true)
        studies
          .listStudyOrders(props.studyQuery)
          .finally(() => setIsLoading(false))
      }
    }, DEBOUNCE_DELAY)
  }, [props.studyQuery])

  const searchStudies = async (
    paginationModel: GridPaginationModel | undefined = undefined
  ) => {
    const filteredQuery = removeEmptyValuesFromObject(props.studyQuery)

    setIsLoading(true)
    if (paginationModel === undefined) {
      await studies.listStudyOrders(filteredQuery)
    } else {
      await studies.listStudyOrders({
        ...filteredQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      })
    }
    setIsLoading(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchStudies()
    }
  }

  const commonColProps = [
    createBasicGridColDef("id", "UUID", 0),
    createBasicGridColDef("readableId", "Study #", 5),
    createBasicGridColDef("name", "Patient Name", 10),
    createBasicGridColDef("orderingPhysician", "Ord. Physician", 10),
    {
      field: "device",
      headerName: "Device",
      flex: 8,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        if (isAdmin) {
          return params.row.assignedDevice
        } else {
          return params.row.device
        }
      },
    },
    {
      field: "date",
      type: "date",
      headerName: "Study Date",
      flex: 8,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, Date>
      ) => formatDate(params.row.date),
    },
  ]

  const columns: GridColDef[] = [
    createBasicGridColDef("mrn", "MRN"),
    {
      field: "dob",
      type: "date",
      headerName: "DOB",
      flex: 8,
      editable: false,
      renderCell: (
        // eslint-disable-next-line
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => formatDate(params.row.dob),
    },
    createBasicGridColDef("referringPhysician", "Ref. Physician", 10),
    createBasicGridColDef("interpretingPhysician", "Int. Physician", 10),
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 10,
      editable: false,
      valueOptions: studyStatusValue,
      renderCell: (params) =>
        StudyStatusIndicator(params.row.status as StudyStatus),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      flex: 9,
      renderCell: (params) => {
        const currentRow = params.row

        const canShowReviewButton = canAnalyzeStudyStatuses.includes(
          currentRow.status
        )
        const showNotes = currentRow.notes

        const analyzeStudy = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          e.stopPropagation()
          nav("/dataset/" + currentRow.activeStudyId)
        }

        const openNoteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          e.stopPropagation()
          setNotesStudyModalStatus(currentRow.notes)
        }

        return (
          <Stack direction="row" alignItems="center" justifyContent="center">
            {canShowReviewButton && (
              <Button size="sm" onClick={analyzeStudy} sx={{ marginRight: 1 }}>
                Review
              </Button>
            )}
            {showNotes && (
              <IconButton onClick={openNoteModal}>
                <EventNoteOutlinedIcon />
              </IconButton>
            )}
          </Stack>
        )
      },
    },
  ]

  const openDeleteModal = (studyId: string) => {
    setStudyToBeDeleted(studyId)
    setDeleteStudyModalStatus(true)
  }

  const openFulfillModal = (studyId: string) => {
    setStudyToBeFulfilled(studyId)
    setFullFillStudyModalStatus(true)
  }

  const openEditModal = (row: StudyTableRow) => {
    setSelectedPatient({
      name: row.name ?? "",
      uuid: row.patientId ?? "",
    })
    setEditModalData({
      uuid: row.id ?? "",
      patientId: row.patientId,
      address1: row.address1,
      address2: row.address2,
      city: row.city,
      state: row.state,
      zip: row.zip,
      scheduledDate: row.scheduledDate,
      oxygenDesatThreshold: row.oxygenDesatThreshold,
      orderingPhysicianId: row.orderingPhysicianId,
      interpretingPhysicianId: row.interpretingPhysicianId,
      assignedDeviceSerialNumber: row.assignedDevice,
      notes: row.notes,
      type: row.type,
      status: row.modalStatus,
    })
    setOpen(true)
  }

  const cols = props.showAdminOptions
    ? [
        ...commonColProps,
        {
          field: "organization",
          headerName: "Organization",
          flex: 12,
          editable: false,
        },
        {
          field: "status",
          headerName: "Status",
          type: "singleSelect",
          flex: 12,
          editable: false,
          valueOptions: studyStatusValue,
        },
        {
          field: "action",
          headerName: "Action",
          sortable: false,
          editable: false,
          flex: 6,
          renderCell: (
            // eslint-disable-next-line
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
          ) => {
            const currentRow = params.row
            const showNotes = currentRow.notes
            const uploadStudy = () => {
              setStudyToBeUploaded({
                id: currentRow.id,
                readableId: currentRow.readableId,
              })
            }
            const analyzeStudy = () => {
              nav("/dataset/" + currentRow.activeStudyId)
            }

            const fulfillStudy = () => {
              openFulfillModal(currentRow.id)
            }

            const rejectStudy = () => {
              studies.rejectStudyInReview(currentRow.id)
            }

            const approveStudy = () => {
              studies.approveStudyForRelease(currentRow.id)
            }

            const downloadEdfFile = () => {
              studies.downloadEdf({ studyId: currentRow.activeStudyId })
            }

            const reprocessStudy = () => {
              studies.reprocessStudy({ studyId: currentRow.activeStudyId })
            }

            const editStudy = () => {
              openEditModal(currentRow)
            }

            const deleteStudy = () => {
              openDeleteModal(currentRow.id)
            }

            const openNoteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              e.stopPropagation()
              setNotesStudyModalStatus(currentRow.notes)
            }

            const status = currentRow.status
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Dropdown>
                  <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{
                      root: { variant: "outlined", color: "neutral" },
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreVert />
                  </MenuButton>
                  <Menu>
                    <MenuItem onClick={editStudy}>Edit</MenuItem>
                    <MenuItem
                      disabled={status !== StudyStatus.PROCESSED}
                      onClick={approveStudy}
                    >
                      Approve
                    </MenuItem>
                    <MenuItem
                      disabled={
                        status !== StudyStatus.PROCESSED &&
                        status !== StudyStatus.READY_FOR_INTERPRETATION &&
                        status !== StudyStatus.READY_FOR_REVIEW &&
                        status !== StudyStatus.REJECTED &&
                        status !== StudyStatus.COMPLETE
                      }
                      onClick={analyzeStudy}
                    >
                      Review
                    </MenuItem>
                    <MenuItem
                      disabled={
                        ![
                          StudyStatus.PROCESSED,
                          StudyStatus.READY_FOR_REVIEW,
                          StudyStatus.READY_FOR_INTERPRETATION,
                          StudyStatus.COMPLETE,
                          StudyStatus.ABORTED,
                          StudyStatus.REJECTED,
                        ].includes(status)
                      }
                      onClick={downloadEdfFile}
                    >
                      Download EDF
                    </MenuItem>
                    <MenuItem
                      disabled={!currentRow.activeStudyId}
                      onClick={reprocessStudy}
                    >
                      Reprocess Study
                    </MenuItem>
                    <MenuItem
                      disabled={
                        status === StudyStatus.IN_PROGRESS &&
                        status === StudyStatus.AWAITING_UPLOAD
                      }
                      onClick={uploadStudy}
                    >
                      Upload
                    </MenuItem>
                    <MenuItem
                      disabled={status !== StudyStatus.ORDERED}
                      onClick={fulfillStudy}
                    >
                      Fulfill
                    </MenuItem>
                    <MenuItem
                      disabled={status !== StudyStatus.PROCESSED}
                      onClick={rejectStudy}
                    >
                      Reject
                    </MenuItem>
                    <ListDivider />
                    <MenuItem color="danger" onClick={deleteStudy}>
                      Delete
                    </MenuItem>
                  </Menu>
                </Dropdown>
                {showNotes && (
                  <IconButton onClick={openNoteModal}>
                    <EventNoteOutlinedIcon />
                  </IconButton>
                )}
              </Stack>
            )
          },
        },
      ]
    : [...commonColProps].concat(columns)

  return (
    <>
      <Suspense fallback={null}>
        {open && (
          <EditStudyModal
            open={open}
            setOpen={setOpen}
            patientData={selectedPatient}
            studyData={editModalData}
            studyQuery={props.studyQuery}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {deleteStudyModalStatus && (
          <DeleteStudyModal
            deleteStudyModalStatus={deleteStudyModalStatus}
            setDeleteStudyModalStatus={setDeleteStudyModalStatus}
            studyToBeDeleted={studyToBeDeleted}
            setStudyToBeDeleted={setStudyToBeDeleted}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {fullFillStudyModalStatus && (
          <FulfillStudyModal
            fullFillStudyModalStatus={fullFillStudyModalStatus}
            setFullFillStudyModalStatus={setFullFillStudyModalStatus}
            studyToBeFulfilled={studyToBeFulfilled}
            setStudyToBeFulfilled={setStudyToBeFulfilled}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {studyOrder && (
          <StudyUploadModal
            studyUploadModalOpenStatus={studyOrder}
            closeStudyUploadModalOpenStatus={() =>
              setStudyToBeUploaded(undefined)
            }
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {newStudyModalOpenStatus && (
          <NewStudyModal
            newStudyModalOpenStatus={newStudyModalOpenStatus}
            setNewStudyModalOpenStatus={setNewStudyModalOpenStatus}
            studyQuery={props.studyQuery}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {notesStudyModalStatus && (
          <NotesStudyModal
            notesStudyModalStatus={notesStudyModalStatus}
            setNotesStudyModalStatus={setNotesStudyModalStatus}
          />
        )}
      </Suspense>
      <Stack spacing={2} sx={{ overflow: "hidden" }}>
        <Stack
          direction="row"
          display={"flex"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Stack
            spacing={2}
            display="flex"
            alignItems="center"
            direction={{ xs: "column", sm: "row" }}
          >
            {isUserPhysician ? (
              <FormControl sx={{ width: 120 }}>
                <Switch
                  startDecorator={
                    <Typography level="body-sm">My Studies</Typography>
                  }
                  checked={selfInterpretingPhysician}
                  onChange={(e) => {
                    setSelfInterpretingPhysician(e.target.checked)
                  }}
                />
              </FormControl>
            ) : null}
            <FormControl sx={{ width: 120 }}>
              <Input
                size="sm"
                placeholder="Patient name"
                value={props.studyQuery.patientName}
                onChange={(e) => {
                  props.setStudyQuery({
                    ...props.studyQuery,
                    patientName: e.target.value,
                  })
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl sx={{ width: 100 }}>
              <Input
                size="sm"
                placeholder="MRN"
                value={props.studyQuery.mrn}
                onChange={(e) => {
                  props.setStudyQuery({
                    ...props.studyQuery,
                    mrn: e.target.value,
                  })
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl>
              <Input
                size="sm"
                placeholder="Interpreting physician"
                value={props.studyQuery.interpretingPhysicianName}
                disabled={selfInterpretingPhysician}
                onChange={(e) => {
                  props.setStudyQuery({
                    ...props.studyQuery,
                    interpretingPhysicianName: e.target.value,
                  })
                  setSearchParams({})
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl sx={{ width: 150 }}>
              <Input
                size="sm"
                placeholder="Referring physician"
                value={props.studyQuery.referringPhysicianName}
                onChange={(e) => {
                  props.setStudyQuery({
                    ...props.studyQuery,
                    referringPhysicianName: e.target.value,
                  })
                }}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            {isAdmin && (
              <FormControl sx={{ width: 150 }}>
                <Select
                  size="sm"
                  startDecorator="Organization"
                  value={props.studyQuery.organizationId}
                  onChange={(_, newValue) => {
                    props.setStudyQuery({
                      ...props.studyQuery,
                      organizationId: newValue || undefined,
                    })
                  }}
                  placeholder="All"
                >
                  <Option key={0} value={""}>
                    All
                  </Option>
                  {organizations.map((organization, index) => (
                    <Option key={index + 1} value={organization.uuid}>
                      {organization.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                size="sm"
                startDecorator="Status"
                multiple
                value={statusFilter}
                onChange={(_, newValue) => {
                  const studyStatuses = newValue.map(
                    (status) => StatusTextToStr[status]
                  )
                  props.setStudyQuery({
                    ...props.studyQuery,
                    studyStatuses,
                  })
                  setStatusFilter(newValue)
                }}
                placeholder="All"
                renderValue={(value) => {
                  return (
                    <Sheet sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {value.map((selectedOption) => (
                        <Chip
                          key={selectedOption.value}
                          sx={{
                            borderRadius: "4px",
                          }}
                          variant="outlined"
                        >
                          {selectedOption.label}
                        </Chip>
                      ))}
                    </Sheet>
                  )
                }}
              >
                {Object.values(
                  props.showAdminOptions ? StudyStatus : SimplifiedStudyStatus
                ).map((status, index) => (
                  <Option key={index} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <IconButton
              size="sm"
              onClick={() => searchStudies()}
              variant="solid"
              color="primary"
            >
              <Search />
            </IconButton>
            <IconButton
              size="sm"
              onClick={() => {
                props.setStudyQuery(emptyStudyQuery(user))
                setSelfInterpretingPhysician(isUserPhysician)
                setStatusFilter([])
                studies.listStudyOrders(emptyStudyQuery(user))
              }}
              variant="outlined"
              color="primary"
            >
              <Refresh />
            </IconButton>
          </Stack>
          <Button
            size="sm"
            startDecorator={<AddIcon />}
            onClick={() => setNewStudyModalOpenStatus(true)}
          >
            New Study
          </Button>
        </Stack>
        <Box sx={{ height: "calc(100% - 52px)" }}>
          <StyledDataGrid
            rows={tableRows}
            columns={cols}
            rowCount={studyCount as number}
            loading={isLoading}
            paginationMode="server"
            hideFooterSelectedRowCount={true}
            disableRowSelectionOnClick={true}
            onRowClick={(params, e) => {
              e.preventDefault()
              e.stopPropagation()
              const { row } = params
              nav("/patients/" + row.patientId)
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
      </Stack>
    </>
  )
}

export default StudyTable
